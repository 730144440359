import React from 'react'
import {X} from '@phosphor-icons/react'
import RegisterUserForm from './RegisterUserForm'

const RegisterUserModal = ({setShowModal,roleValue}) => {

  return (
    <div className=' w-full h-fit  px-2 py-2 absolute top-0 left-0 bg-gray-800 '>

      <div className='flex w-full justify-end'>
        <button onClick={()=>{
          setShowModal(false)
        }}><X className='text-2xl text-white'/></button>
      </div>
      <div>
      
        <h1 className='text-center text-xl text-white font-bold '>Register User</h1>
        
      </div>
        <div className='w-full h-full'>
          <RegisterUserForm setShowModal={setShowModal} roleValue={roleValue}/>
        </div>

    </div>
  )
}

export default RegisterUserModal