import classNames from 'classnames';

import React from 'react';
import { DOTS, usePagination } from './UsePagination';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

const Pages = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange!.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange![paginationRange!.length - 1];
  return (
    <>
      <div className="flex gap-3">
        <button
          disabled={currentPage === 1}
          onClick={onPrevious}
          className={classNames(
            'bg-white p-2 border cursor-pointer border-color-border rounded-[8px]',
          )}
        >
          <CaretLeft size={18} />
        </button>
        {paginationRange!.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <div key={index} className="flex items-center gap-3">
                <div className="w-1 h-1 rounded-full bg-[#58a2c342]"></div>{' '}
                <div className="w-1 h-1 rounded-full bg-[#58a2c342]"></div>{' '}
                <div className="w-1 h-1 rounded-full bg-[#58a2c342]"></div>{' '}
              </div>
            );
          }
          return (
            <p
              key={index}
              onClick={() => onPageChange(pageNumber)}
              className={classNames(
                'px-3 cursor-pointer py-2 rounded-[8px]',
                pageNumber === currentPage
                  ? 'bg-blue-200 text-theme-blue'
                  : 'text-black bg-theme-white',
              )}
            >
              {pageNumber}
            </p>
          );
        })}
        {/* <div className="flex items-center gap-2">
          <p className="px-3 cursor-pointer py-2 bg-theme-light-blue text-theme-blue rounded-[8px]">
            1
          </p>
          <p className="px-3 cursor-pointer py-2 bg-theme-white rounded-[8px]">
            2
          </p>
        </div> */}

        <button
          disabled={currentPage === lastPage}
          onClick={onNext}
          className={classNames(
            'bg-white p-2 cursor-pointer border border-color-border rounded-[8px]',
          )}
        >
          <CaretRight size={18} />
        </button>
      </div>
    </>
  );
};

export default Pages;
