import classNames from 'classnames';
import React from 'react';
interface State {
  onClick: any;
  text: string;
  conditon?: boolean;
  isNvAdmin?: boolean;
}

const Tab = ({ conditon, text, onClick, isNvAdmin }: State) => {
  return (
    <>
      <p
        onClick={onClick}
        className={classNames(
          'p-[14px] border-t-2 cursor-pointer',
          conditon
            ? `bg-white ${'border-blue-400'}`
            : 'bg-gray-200 text-gray-400 border-transparent',
        )}
      >
        {text}
      </p>
    </>
  );
};

export default Tab;
