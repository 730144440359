import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getSituationalMatchesActions,
  getSituationalPointsOfAMatchActions,
} from '../../../redux/actions/SituationalActions';
import { RootState } from '../../../types';
import {
  Calendar,
  CalendarBlank,
  Trash,
  TrashSimple,
} from '@phosphor-icons/react';
import {
  deleteSituationalPointOfAMatch,
  updateSituationalPointOfAMatch,
} from '../../../api/Admin/situationalData';
import classNames from 'classnames';
import Loading from '../../../libs/Loading';
import { toast } from 'react-toastify';
import { formatDateTime } from '.';

const IndividualMatch = () => {
  const location = useLocation();
  const matchId = location.pathname.split('/')[2];

  const { data, match_id, loading } = useSelector(
    (state: RootState) => state.getSituationalPointsOfAMatch,
  );
  const { query } = useSelector(
    (state: RootState) => state.getSituationalMatches,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (match_id !== matchId)
      dispatch(getSituationalPointsOfAMatchActions({ match_id: matchId }));
  }, []);

  const updateSituationPoint = async ({ data, point_id }) => {
    try {
      await updateSituationalPointOfAMatch({
        data,
        match_id: matchId,
        point_id,
      });
      dispatch(getSituationalPointsOfAMatchActions({ match_id: matchId }));
      dispatch(getSituationalMatchesActions(query));
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSituationPoint = async ({ point_id }) => {
    try {
      toast.loading('deleting a situation');
      await deleteSituationalPointOfAMatch({
        match_id: matchId,
        point_id,
      });
      toast.dismiss();
      toast.success('situation deleted successfully');

      dispatch(getSituationalPointsOfAMatchActions({ match_id: matchId }));
      dispatch(getSituationalMatchesActions(query));
    } catch (error) {
      toast.dismiss();
      toast.error('some error occured');

      console.error(error);
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full min-w-[1300px] h-full mb-64">
          <div className="filter px-8  pt-10 w-full h-full md:gap-x-8">
            <div className="w-full rounded-lg  bg-white h-full pt-7 pb-9 flex flex-col">
              <div className="flex items-center justify-between">
                <div className="flex gap-x-2">
                  <div className="w-2 h-11 bg-bgblue"></div>
                  <div className="bg-bgfadeblack text-white rounded-r-full px-4 py-3">
                    <p className="team para font-bold  text-sm">
                      <span className="opacity-80">
                        {' '}
                        Situational Points for{' '}
                      </span>
                      <span className="font-bold opacity-100">
                        {data?.team1}
                      </span>{' '}
                      vs{' '}
                      <span className="font-bold opacity-100">
                        {data?.team2}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-6 pr-12">
                  <div className="flex gap-3 items-center">
                    <p className="font-bold">Place: </p>
                    {/* <p>{formatDateTime(data?.match_id?.datetimeUTC)}</p> */}
                    <p className="font-medium ">
                      {data?.match_id?.venue?.details?.name},{' '}
                      {data?.match_id?.venue?.details?.city}
                    </p>
                  </div>
                  <p>|</p>
                  <div className="flex gap-3 items-center">
                    <p className="font-bold">Date: </p>
                    <p className="font-medium ">
                      {formatDateTime(data?.match_id?.datetimeUTC)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="px-8 w-full mt-4">
                <table className="border-collapse max-w-[100%] border min-w-[1200px] overflow-x-auto border-bordercheckbox mt-4 w-full px-10">
                  <thead>
                    <tr className="text-bgparagraph opacity-[0.5] font-bold">
                      <th className="border border-bordergrey p-2  bg-bggreyfade  py-[10px]">
                        <div className="flex justify-start px-4">Index</div>
                      </th>
                      <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                        Siutuation
                      </th>
                      <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                        Impact
                      </th>
                      <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                        In favor of
                      </th>
                      <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                        Remove
                      </th>
                    </tr>
                  </thead>

                  {data?.situationalPoints?.map((s, index) => {
                    return (
                      <tr className="">
                        <td className="border border-bordergrey px-4 text-start   text-base text-black py-[10px]">
                          <p>{index + 1}</p>
                        </td>
                        <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                          {s.situation}
                        </td>
                        {/* <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {d?.correctCount}
                            </td> */}
                        <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                          <select
                            name=""
                            value={s.impact}
                            className={classNames(
                              'bg-transparent',
                              !s.impact && 'opacity-60',
                            )}
                            id=""
                            onChange={e =>
                              updateSituationPoint({
                                data: { impact: e.target.value },
                                point_id: s._id,
                              })
                            }
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {[
                              'No Impact',
                              'Low',
                              'Moderate',
                              'High',
                              'Critical',
                            ].map(s => {
                              return <option value={s}>{s}</option>;
                            })}
                          </select>
                        </td>
                        <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                          <select
                            value={s.inFavorOf}
                            name=""
                            id=""
                            className={classNames(
                              'bg-transparent',
                              !s.inFavorOf && 'opacity-60',
                            )}
                            onChange={e =>
                              updateSituationPoint({
                                data: { inFavorOf: e.target.value },
                                point_id: s._id,
                              })
                            }
                          >
                            <option selected disabled value="">
                              Select
                            </option>

                            {[data?.team1, data?.team2, 'None'].map(s => {
                              return <option value={s}>{s}</option>;
                            })}
                          </select>
                        </td>
                        <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                          <div className="flex justify-center">
                            <div
                              className="p-2 rounded-full cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                deleteSituationPoint({ point_id: s._id })
                              }
                            >
                              <Trash className="text-rose-500 " />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IndividualMatch;
