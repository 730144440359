import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../libs/Loading';
import { getPredictionStatsAction } from '../../../redux/actions/StatsActions';
import { RootState } from '../../../types';
import UserdropdownMatchType from '../common/UserDropdown/Matchtype';

const PredictionStats = () => {
  const [selectedSport, setSelectedSport] = useState<string>('NFL');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getPredictionStatsAction({
        sport: selectedSport === 'MLB' ? 'BASEBALL' : 'FOOTBALL',
      }),
    );
  }, [selectedSport]);

  const { data, loading } = useSelector(
    (state: RootState) => state.getPredictionStats,
  );

  const checkIsNan = val => {
    if (isNaN(val)) {
      return 'N/A';
    } else return val;
  };

  return (
    <div className="w-full h-full mb-64">
      <div className="filter px-8 flex pt-10 w-full h-full md:gap-x-8">
        <div className="w-full rounded-lg bg-white h-full pt-7 pb-9 flex flex-col">
          <div className="flex items-center justify-between">
            <div className="flex gap-x-2">
              <div className="w-2 h-11 bg-bgblue"></div>
              <div className="bg-bgfadeblack text-white rounded-r-full px-4 py-3">
                <p className="team para font-bold text-sm">Predictions Stats</p>
              </div>
            </div>
          </div>

          <div className="px-8 mt-4">
            <UserdropdownMatchType
              selected={selectedSport}
              setSelected={setSelectedSport}
            />
          </div>

          <div className="px-8 mt-4">
            {loading ? (
              <Loading />
            ) : (
              <table className="border-collapse border border-bordercheckbox mt-4 w-full">
                <thead>
                  <tr className="text-bgparagraph opacity-[0.5] font-bold">
                    <th className="border border-bordergrey p-2 w-[20%]  bg-bggreyfade  py-[10px]">
                      <div className="flex justify-start px-4">Date</div>
                    </th>
                    <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                      Open AI Count
                    </th>
                    <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                      Percentage
                    </th>
                    <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                      ELO Count
                    </th>
                    {/* <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                      OpenAI Count
                    </th> */}
                    <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                      Percentage
                    </th>
                    <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                      MLP Count
                    </th>
                    <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                      Percentage
                    </th>
                  </tr>
                </thead>
                {data && (
                  <tbody className="">
                    {/* {startingpitchers.map((tab: StartingPitchers, index) => { */}
                    {/* return ( */}
                    <>
                      {
                        <tr className="font-bold">
                          <td className="border border-bordergrey px-4 text-start flex  items-center text-base text-black py-[10px]">
                            <p>Aggregate</p>
                          </td>
                          <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                            {data.aggregate?.OPENAI?.correctCount}/
                            {data.aggregate?.OPENAI?.totalCount}
                          </td>
                          <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                            {checkIsNan(
                              Math.round(
                                (data.aggregate?.OPENAI?.correctCount /
                                  data.aggregate?.OPENAI?.totalCount) *
                                  100,
                              ),
                            )}
                            {isNaN(
                              Math.round(
                                (data.aggregate?.OPENAI?.correctCount /
                                  data.aggregate?.OPENAI?.totalCount) *
                                  100,
                              ),
                            )
                              ? ''
                              : '%'}
                          </td>
                          <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                            {data.aggregate?.ELO?.correctCount}/
                            {data.aggregate?.ELO?.totalCount}
                          </td>
                          {/* <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                            {data.aggregate?.predictionCounts?.OPENAI}
                          </td> */}
                          <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                            {checkIsNan(
                              Math.round(
                                (data.aggregate?.ELO?.correctCount /
                                  data.aggregate?.ELO?.totalCount) *
                                  100,
                              ),
                            )}
                            {isNaN(
                              Math.round(
                                (data.aggregate?.ELO?.correctCount /
                                  data.aggregate?.ELO?.totalCount) *
                                  100,
                              ),
                            )
                              ? ''
                              : '%'}
                          </td>
                          <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                            {data.aggregate?.MLPMODEL?.correctCount}/
                            {data.aggregate?.MLPMODEL?.totalCount}
                          </td>
                          <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                            {checkIsNan(
                              Math.round(
                                (data.aggregate?.MLPMODEL?.correctCount /
                                  data.aggregate?.MLPMODEL?.totalCount) *
                                  100,
                              ),
                            )}
                            {isNaN(
                              Math.round(
                                (data.aggregate?.MLPMODEL?.correctCount /
                                  data.aggregate?.MLPMODEL?.totalCount) *
                                  100,
                              ),
                            )
                              ? ''
                              : '%'}
                          </td>
                        </tr>
                      }
                      {data.predictions.map((d, index) => {
                        return (
                          <tr key={index} className="">
                            <td className="border border-bordergrey px-4 text-start flex  items-center text-base text-black py-[10px]">
                              <p>{d._id}</p>
                            </td>
                            <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {d?.byProvider[0]?.correctCount}/
                              {d?.byProvider[0]?.totalCount}
                            </td>
                            {/* <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {d?.correctCount}
                            </td> */}
                            <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {checkIsNan(
                                Math.round(
                                  (d?.byProvider[0]?.correctCount /
                                    d?.byProvider[0]?.totalCount) *
                                    100,
                                ),
                              )}
                              {isNaN(
                                Math.round(
                                  (d?.byProvider[0]?.correctCount /
                                    d?.byProvider[0]?.totalCount) *
                                    100,
                                ),
                              )
                                ? ''
                                : ' %'}
                            </td>
                            <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {d?.byProvider[1]?.correctCount}/
                              {d?.byProvider[1]?.totalCount}
                            </td>
                            <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {checkIsNan(
                                Math.round(
                                  (d?.byProvider[1]?.correctCount /
                                    d?.byProvider[1]?.totalCount) *
                                    100,
                                ),
                              )}
                              {isNaN(
                                Math.round(
                                  (d?.byProvider[1]?.correctCount /
                                    d?.byProvider[1]?.totalCount) *
                                    100,
                                ),
                              )
                                ? ''
                                : ' %'}
                            </td>
                            <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {d?.byProvider[2]?.correctCount}/
                              {d?.byProvider[2]?.totalCount}
                            </td>
                            <td className="border border-bordergrey text-center text-base text-bgfadeblack py-[10px] ">
                              {checkIsNan(
                                Math.round(
                                  (d?.byProvider[2]?.correctCount /
                                    d?.byProvider[2]?.totalCount) *
                                    100,
                                ),
                              )}
                              {isNaN(
                                Math.round(
                                  (d?.byProvider[2]?.correctCount /
                                    d?.byProvider[2]?.totalCount) *
                                    100,
                                ),
                              )
                                ? ''
                                : ' %'}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                    {/* ); */}
                    {/* })} */}
                  </tbody>
                )}
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredictionStats;
