import axios from 'axios';
import { serialize } from '../../libs/serialise';

export const getSituationalMatches = async ({ page, limit }) => {
  const uri = serialize({ page, limit });
  return await axios.get(
    `${process.env.REACT_APP_API_URL_2}/situational-data/all_matches?${uri}`,
  );
};

export const getSituationalPointsOfAMatch = async ({ match_id }) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_2}/situational-data/${match_id}`,
  );
};

export const updateSituationalPointOfAMatch = async ({
  match_id,
  point_id,
  data,
}) => {
  return await axios.patch(
    `${process.env.REACT_APP_API_URL_2}/situational-data/${match_id}/${point_id}`,
    data,
  );
};

export const deleteSituationalPointOfAMatch = async ({
  match_id,
  point_id,
}) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_2}/situational-data/${match_id}/${point_id}`,
  );
};
