import React from 'react';

const WoWooTabel = ({
  withOdds,
  withOutOdds,
  mlpSelection,
  nm2,
  nm3,
  nm4,
  nm5,
  nmpl,
  isMmlpSelection,
  mm1,
  mm2,
  sport = 'BASKETBALL',
}: {
  withOdds: any;
  withOutOdds: any;
  mlpSelection: any;
  nm2: any;
  nm3: any;
  nm4: any;
  nm5: any;
  nmpl: any;
  isMmlpSelection: any;
  mm1: any;
  mm2?: any;
  sport: string;
}) => {
  return (
    <table className="table-auto w-full text-center">
      <tbody>
        <tr className="border-b">
          <td className="md:px-4 px-0 py-2">m/s:</td>
          <td className="flex justify-center items-center  py-2 md:gap-2">
            {isMmlpSelection ? mlpSelection : 'N/A'}
          </td>
        </tr>
        {sport === 'BASKETBALL' && (
          <>
            <tr className="border-b">
              <td className="md:px-4 px-0 py-2 w-1/4">NM1:</td>
              <td className="flex flex-col md:flex-row justify-center py-2 items-center md:gap-2">
                {withOdds}
              </td>
            </tr>
            {/* <tr className="border-b">
          <td className="md:px-4 px-0 py-2 ">wo/o:</td>
          <td className="flex flex-col md:flex-row justify-center items-center  py-2 md:gap-2">
            {withOutOdds}
          </td>
        </tr> */}
            <tr className="border-b">
              <td className="md:px-4 px-0 py-2 ">NM2:</td>
              <td className="flex flex-col md:flex-row justify-center items-center  py-2 md:gap-2">
                {nm2 ?? 'N/A'}
              </td>
            </tr>
            <tr className="border-b">
              <td className="md:px-4 px-0 py-2 ">NM3:</td>
              <td className="flex flex-col md:flex-row justify-center items-center  py-2 md:gap-2">
                {nm3 ?? 'N/A'}
              </td>
            </tr>
            <tr className="border-b">
              <td className="md:px-4 px-0 py-2 ">NM4:</td>
              <td className="flex flex-col md:flex-row justify-center items-center  py-2 md:gap-2">
                {nm4 ?? 'N/A'}
              </td>
            </tr>
            <tr className="border-b">
              <td className="md:px-4 px-0 py-2 ">NM5:</td>
              <td className="flex flex-col md:flex-row justify-center items-center  py-2 md:gap-2">
                {nm5 ?? 'N/A'}
              </td>
            </tr>
            <tr className="">
              <td className="md:px-4 px-0 py-2 ">NMPL:</td>
              <td className="flex flex-col md:flex-row justify-center items-center  py-2 md:gap-2">
                {nmpl ?? 'N/A'}
              </td>
            </tr>
          </>
        )}

        {sport === 'BASEBALL' && (
          <>
            <tr className="">
              <td className="md:px-4 px-0 py-2 ">MM1:</td>
              <td className="flex flex-col md:flex-row justify-center py-2 items-center md:gap-2">
                {mm1 ?? 'N/A'}
              </td>
            </tr>
            {mm2 ? (
              <tr className="">
                <td className="md:px-4 px-0 py-2 ">MM2:</td>
                <td className="flex flex-col md:flex-row justify-center py-2 items-center md:gap-2">
                  {mm2 ?? 'N/A'}
                </td>
              </tr>
            ) : (
              <></>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

export default WoWooTabel;
