import axios from 'axios';
import { serialize } from '../../libs/serialise';
import axiosInstance from '../../utils/axiosInstance';

export const getPredictionStatsApi = async ({ sport }) => {
  try {
    const uri = serialize({ sport });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/prediction/stats?${uri}`,
    );

    if (response.status !== 200) {
      throw new Error(`Failed to fetch data: ${response.status}`);
    }

    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    return {
      data: null,
      error: `An error occurred:`,
    };
  }
};
