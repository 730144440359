export const GET_MATCH_SCHEDULE_FOR_DATE_REQUEST =
  'GET_MATCH_SCHEDULE_FOR_DATE_REQUEST';
export const GET_MATCH_SCHEDULE_FOR_DATE_SUCCESS =
  'GET_MATCH_SCHEDULE_FOR_DATE_SUCCESS';
export const GET_MATCH_SCHEDULE_FOR_DATE_FAILURE =
  'GET_MATCH_SCHEDULE_FOR_DATE_FAILURE';

export const GET_PREDICTION_FOR_DATE_REQUEST =
  'GET_PREDICTION_FOR_DATE_REQUEST';
export const GET_PREDICTION_FOR_DATE_SUCCESS =
  'GET_PREDICTION_FOR_DATE_SUCCESS';
export const GET_PREDICTION_FOR_DATE_FAILURE =
  'GET_PREDICTION_FOR_DATE_FAILURE';

export const GET_NON_PREDICTED_MATCHES_FOR_DATE_REQUEST =
  'GET_NON_PREDICTED_MATCHES_FOR_DATE_REQUEST';
export const GET_NON_PREDICTED_MATCHES_FOR_DATE_SUCCESS =
  'GET_NON_PREDICTED_MATCHES_FOR_DATE_SUCCESS';
export const GET_NON_PREDICTED_MATCHES_FOR_DATE_FAILURE =
  'GET_NON_PREDICTED_MATCHES_FOR_DATE_FAILURE';

export const GET_RUN_PREDICTIONS_PROM_PROMPTID_REQUEST =
  'GET_RUN_PREDICTIONS_PROM_PROMPTID_REQUEST';
export const GET_RUN_PREDICTIONS_PROM_PROMPTID_SUCCESS =
  'GET_RUN_PREDICTIONS_PROM_PROMPTID_SUCCESS';
export const GET_RUN_PREDICTIONS_PROM_PROMPTID_FAILURE =
  'GET_RUN_PREDICTIONS_PROM_PROMPTID_FAILURE';

export const GET_REFRESH_DATA_REQUEST = 'GET_REFRESH_DATA_REQUEST';
export const GET_REFRESH_DATA_SUCCESS = 'GET_REFRESH_DATA_SUCCESS';
export const GET_REFRESH_DATA_FAILURE = 'GET_REFRESH_DATA_FAILURE';

export const GET_DOWNLOAD_PROMPT_BYMATCH_ID_REQUEST =
  'GET_DOWNLOAD_PROMPT_BYMATCH_ID_REQUEST';
export const GET_DOWNLOAD_PROMPT_BYMATCH_ID_SUCCESS =
  'GET_DOWNLOAD_PROMPT_BYMATCH_ID_SUCCESS';
export const GET_DOWNLOAD_PROMPT_BYMATCH_ID_FAILURE =
  'GET_DOWNLOAD_PROMPT_BYMATCH_ID_FAILURE';
