import { betType } from '../app/admincomponents/Predict/Interface';

export const formattedOdds = (odds: number) => {
  let val = 0;
  if (odds < 0) {
    val = 1 + Math.abs(100 / odds);
  } else {
    val = 1 + odds / 100;
  }
  return Number(val.toFixed(2));
};

export const findBetCat = (val: number) => {
  const val2 = formattedOdds(val);

  if (val2 >= 2) {
    return betType.high_return;
  } else if (val2 < 2 && val2 > 1.4) {
    return betType.medium_return;
  } else {
    return betType.low_return;
  }
};

export function americanToPct(americanOdds) {
  if (americanOdds > 0) {
    return 100 / (americanOdds + 100);
  } else if (americanOdds < 0) {
    return -americanOdds / (-americanOdds + 100);
  } else {
    return 0;
  }
}
