export const GET_SITUATIONAL_MATCHES_REQUEST =
  'GET_SITUATIONAL_MATCHES_REQUEST';
export const GET_SITUATIONAL_MATCHES_SUCCESS =
  'GET_SITUATIONAL_MATCHES_SUCCESS';
export const GET_SITUATIONAL_MATCHES_FAILURE =
  'GET_SITUATIONAL_MATCHES_FAILURE';

export const GET_SITUATIONAL_POINTS_OF_A_MATCH_REQUEST =
  'GET_SITUATIONAL_POINTS_OF_A_MATCH_REQUEST';
export const GET_SITUATIONAL_POINTS_OF_A_MATCH_SUCCESS =
  'GET_SITUATIONAL_POINTS_OF_A_MATCH_SUCCESS';
export const GET_SITUATIONAL_POINTS_OF_A_MATCH_FAILURE =
  'GET_SITUATIONAL_POINTS_OF_A_MATCH_FAILURE';
