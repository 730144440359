import React from 'react';

const CellTable = ({
  prediction,
  mlpSelection,
  isMmlpSelection,
  sport = 'BASKETBALL',
}: {
  prediction: any;
  mlpSelection: any;
  isMmlpSelection: any;
  sport: string;
}) => {
  return (
    <table className="table-auto w-full text-center">
      <tbody>
        <tr className="border-b">
          <td className="md:px-4 px-0 py-2">m/s:</td>
          <td className="flex justify-center items-center  py-2 md:gap-2">
            {isMmlpSelection ? mlpSelection : 'N/A'}
          </td>
        </tr>

        <>
          <tr className="">
            <td className="md:px-4 px-0 py-2 ">NFM1:</td>
            <td className="flex flex-col md:flex-row justify-center py-2 items-center md:gap-2">
              {prediction ?? 'N/A'}
            </td>
          </tr>
        </>
      </tbody>
    </table>
  );
};

export default CellTable;
