import React from 'react';
type PropType = {
  team1: string;
  team2: string;
  logo1?: string;
  logo2?: string;
  timings?: string;
  markedPoints?: number;
  totalPoints?: number;
};

export default function MatchCard({
  team1,
  team2,
  logo1,
  logo2,
  timings,
  totalPoints,
  markedPoints,
}: PropType) {
  return (
    <div className="rounded-lg bg-white px-5 py-1">
      <div className="flex flex-col space-y-2 py-4">
        <div className="flex items-center justify-between">
          <div className="flex w-full text-xs items-center justify-between space-x-1">
            <div className=" font-medium font-arimo">{'NBA'}</div>
            <div>
              <p>
                Marked Points: {markedPoints}/{totalPoints}
              </p>
            </div>
            <div className="flex space-x-2">
              <div className="text-[10px] font-medium font-arimo">
                {timings}
              </div>
            </div>
          </div>
        </div>
        <div className="grid ">
          <div className="flex flex-col space-y-1 mt-1.5">
            <div className="flex items-center flex-row space-x-2">
              <img className="w-7" src={logo1} alt="team1" />
              <div className="font-bold text-[13px] font-arimo">{team1}</div>
            </div>
            <div className="flex flex-row items-center space-x-2 mt-1">
              <div className="">
                <img className="w-7" src={logo2} alt="team" />
              </div>
              <div className="font-bold text-[13px]  font-arimo">{team2}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
