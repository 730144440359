import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ setFormData , formData }) => {
  const [content, setContent] = useState('');

  const handleChange = (value) => {
    setContent(value);
    setFormData({...formData,textBody:value}); // Pass content to parent component
  };

  return (
    <div className='w-full'>
      <ReactQuill
        value={formData.textBody}
        onChange={handleChange}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
      />
    </div>
  );
};

TextEditor.modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }], // Custom font sizes
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['link',],
      ['clean']
    ],
  };

TextEditor.formats = [
  'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'align', 'color', 'background', 'link', 'image', 'video'
];

export default TextEditor;
