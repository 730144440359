import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Passwordcomponent from '../../components/common/auth/PasswordComponent';
import EmailComponent from '../../components/common/auth/EmailComponent';
import SBSLeft from '../../components/common/auth/SbsLeftComponent';
import Subtract from 'assets/images/new_logo_sp.svg';
import Help from 'assets/images/help-web-button.svg';
import GoogleIcon from 'assets/images/googleIcon.svg';
import {
  AdminLoginApi,
  sendLoginCode,
  validateLoginCode,
} from '../../../api/userApis';
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(false); // New state variable

  const onInputEmail = e => {
    setEmail(e.target.value);
    setIsFormValid(e.target.value); // Update form validity
  };

  const onInputPassword = e => {
    setPassword(e.target.value);
    // setIsFormValid(email && e.target.value); // Update form validity
  };
  // const [passwordType, setPasswordType] = useState('password');

  // const togglePassword = () => {
  //   if (passwordType === 'password') {
  //     setPasswordType('text');
  //     return;
  //   }
  //   setPasswordType('password');
  // };

  const sendOTPHandler = async e => {
    e.preventDefault();
    try {
      toast.loading('Sending OTP');
      await sendLoginCode({ email });

      setShowOTPInputField(true);
      toast.dismiss();
      toast.success('OTP sent');
    } catch (error: any) {
      toast.dismiss();

      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      );
    }
  };

  const handleLogin = async event => {
    event.preventDefault();
    try {
      toast.loading('Logging you in...');
      const response = await validateLoginCode({ email, loginCode: password });
      window.localStorage.setItem(
        'userInfo',
        JSON.stringify(response.data.payload.user),
      );
      window.localStorage.setItem(
        'token',
        JSON.stringify(response.data.payload.token),
      );
      const userRole = response?.data?.payload?.user?.role;
      const userSubRole = response?.data?.payload?.user?.subRole;
      if (response) {
        if (userRole === 'ADMIN' && userSubRole === 'INVESTOR') {
          navigate('/tracker');
        }
        if (userRole === 'ADMIN' && !userSubRole) {
          navigate('/');
        }
        toast.dismiss();
        toast.success('Logged you in...');
      } else {
      }
    } catch (error: any) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      );
    }
  };
  const handleSignup = () => {
    // Perform login logic

    // After successful login, navigate to the home page
    navigate('/signup');

    const handleEmailConfirmation = () => {
      navigate('/emailconfirmation');
    };
  };

  // useEffect(() => {
  //   const loggedInUser = JSON.parse(localStorage.getItem('userInfo')!);

  //
  //   const userRole = loggedInUser?.role;
  //   const userSubRole = loggedInUser?.subRole;

  //   const isInvestor = userRole === 'ADMIN' && userSubRole === 'INVESTOR';
  //
  //   if (isInvestor) {
  //     navigate('/tracker');
  //   } else {
  //     navigate('/');
  //   }
  // }, []);

  const [showOtpInputField, setShowOTPInputField] = useState(false);

  return (
    <>
      <div className="w-full">
        <div className=" w-full h-screen flex font-Poppins">
          <SBSLeft />
          <div className="lg:w-[65%] w-full  flex flex-col lg:justify-center mt-20 lg:mt-0 relative">
            <div className="w-[100%] flex flex-col items-center justify-center">
              <div className="cursor-pointer">
                <img src={Subtract} className="w-16 h-16" />
              </div>
              <div className="md:w-[50%] md:px-0 px-12  pt-3">
                <div className="login-in flex flex-col items-center gap-y-3">
                  <p className="text-bgparagraph md:text-left text-center text-3xl font-bold">
                    Login To Your Account
                  </p>
                </div>
                <form className="pt-6 pb-2">
                  {showOtpInputField ? (
                    <></>
                  ) : (
                    <EmailComponent onInputEmail={onInputEmail} email={email} />
                  )}
                  {showOtpInputField ? (
                    <Passwordcomponent
                      password={password}
                      onInputPassword={onInputPassword}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="flex justify-between pb-8">
                    {/* <div className="flex gap-x-2">
                      <input
                        type="checkbox"
                        className="w-5 h-5 border-border rounded-lg outline-0 "
                      />
                      <p className="text-bggrey font-normal text-sm">
                        Remember me
                      </p>
                    </div> */}
                    {/* <Link to="/emailconfirmation">
                      <p className="text-sm font-medium text-bgblue">
                        Forgot Password?
                      </p>
                    </Link> */}
                  </div>

                  <button
                    disabled={!isFormValid} // Disable button if form is not valid
                    onClick={showOtpInputField ? handleLogin : sendOTPHandler}
                    className={` border-bgblue cursor-pointer border-2 w-full py-[10px] border-border rounded-lg font-bold text-bgblue ${
                      isFormValid ? '' : ' '
                    }`}
                  >
                    {!showOtpInputField ? 'Send Code' : 'Log In'}
                  </button>
                </form>
                {/* <div className="w-full flex gap-x-3 items-center justify-center px-4 py-[10px] border-borderButton rounded-md border-2  cursor-pointer">
                  <div>
                    <img src={GoogleIcon} />
                  </div>

                  <button className="text-base font-semibold">
                    Sign in with Google
                  </button>
                </div>
                <div className="text-sm font-normal text-center pt-6 cursor-pointer">
                  <p>
                    Don’t have an account?
                    <span className="text-bgblue pl-2" onClick={handleSignup}>
                      Sign up
                    </span>
                  </p>
                </div> */}
              </div>
            </div>

            <div className="w-[100%] flex justify-between px-10 absolute bottom-0 py-4">
              <div className="w-full">
                <p className="font-medium text-center text-xs">
                  v1.0 Copyright 2024 SportsPulse AI Inc. All rights Reserved
                </p>
              </div>
              {/* <div className="flex gap-x-2 items-center justify-center">
                <img src={Help} />
                <p className="font-medium text-xs">
                  Connect with{' '}
                  <span className="text-bgblue">Customer Support</span>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
