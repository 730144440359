export const accessToken = () => {
  const token = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token') || '')
    : '';
  //

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token?.token}`,
    },
  };
  return config;
};
