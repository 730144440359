import { AnyAction } from 'redux';

import {
  GET_DOWNLOAD_PROMPT_BYMATCH_ID_FAILURE,
  GET_DOWNLOAD_PROMPT_BYMATCH_ID_REQUEST,
  GET_DOWNLOAD_PROMPT_BYMATCH_ID_SUCCESS,
  GET_MATCH_SCHEDULE_FOR_DATE_FAILURE,
  GET_MATCH_SCHEDULE_FOR_DATE_REQUEST,
  GET_MATCH_SCHEDULE_FOR_DATE_SUCCESS,
  GET_NON_PREDICTED_MATCHES_FOR_DATE_FAILURE,
  GET_NON_PREDICTED_MATCHES_FOR_DATE_REQUEST,
  GET_NON_PREDICTED_MATCHES_FOR_DATE_SUCCESS,
  GET_PREDICTION_FOR_DATE_FAILURE,
  GET_PREDICTION_FOR_DATE_REQUEST,
  GET_PREDICTION_FOR_DATE_SUCCESS,
  GET_REFRESH_DATA_FAILURE,
  GET_REFRESH_DATA_REQUEST,
  GET_REFRESH_DATA_SUCCESS,
} from '../constants/DashboardConstants';

export const getMatchScheduleforDateReducer = (
  state = {
    data: null,
    error: null,
    loading: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_MATCH_SCHEDULE_FOR_DATE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_MATCH_SCHEDULE_FOR_DATE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_MATCH_SCHEDULE_FOR_DATE_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getPredictionForDateReducer = (
  state = {
    data: null,
    error: null,
    loading: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_PREDICTION_FOR_DATE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PREDICTION_FOR_DATE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_PREDICTION_FOR_DATE_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getNonPredictedMatchesForDateReducer = (
  state = {
    data: null,
    error: null,
    loading: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_NON_PREDICTED_MATCHES_FOR_DATE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_NON_PREDICTED_MATCHES_FOR_DATE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_NON_PREDICTED_MATCHES_FOR_DATE_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getRunPredictionFromPromptIdReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_PREDICTION_FOR_DATE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PREDICTION_FOR_DATE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_PREDICTION_FOR_DATE_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getRefreshDataReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_REFRESH_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_REFRESH_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_REFRESH_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getDownloadPromptByMatchIdReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_DOWNLOAD_PROMPT_BYMATCH_ID_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DOWNLOAD_PROMPT_BYMATCH_ID_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_DOWNLOAD_PROMPT_BYMATCH_ID_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};
