import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import React from 'react';

const ShowMitchPredictionPopUp = ({
  setShowMitchPredictionPopUp,
  mitchPrediction,
  setMitchPrediction,
  agreeWithTheModel,
  sendEmailHandler,
  setAgreeWithTheModel,
}) => {
  return (
    <div
      onClick={() => setShowMitchPredictionPopUp(false)}
      className="fixed top-0 left-0 bg-black z-[999999] bg-opacity-40 w-screen h-screen"
    >
      <div
        onClick={e => e.stopPropagation()}
        className="absolute top-1/2 md:min-w-[500px] left-1/2 -translate-x-1/2 rounded-md -translate-y-1/2 bg-white px-6 py-4"
      >
        <div className="flex justify-between pb-1 border-b items-center">
          <p className="text-lg font-medium">Add MLP Selection</p>
          <X
            onClick={() => setShowMitchPredictionPopUp(false)}
            className="cursor-pointer"
            size={24}
          />
        </div>

        <div className="pt-3">
          <div className="flex items-center gap-6">
            <p>Match: </p>
            <div className="md:w-2/5 w-3/5">
              <div className="flex items-center justify-between">
                <div className="grid gap-1 w-[45%]">
                  <div className="flex font-medium items-center gap-2">
                    <img
                      className="w-8"
                      src={mitchPrediction.match?.hometeam?.image}
                      alt=""
                    />
                    <p>{mitchPrediction.match?.hometeam?.abbreviation}</p>
                  </div>
                </div>
                <div>
                  <img
                    className="w-5 "
                    src="https://staging.majorleaguepicks.ai/static/media/Group%20392.0c182092185a424278e84d5d9fad4464.svg"
                    alt=""
                  />
                </div>
                <div className="grid gap-1 w-[45%] justify-end">
                  <div className="flex font-medium items-center gap-2">
                    <img
                      className="w-8"
                      src={mitchPrediction.match?.awayteam?.image}
                      alt=""
                    />
                    <p>{mitchPrediction.match?.awayteam?.abbreviation}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-4 pt-4 items-center focus:outline-none">
            <p>Winner Prediction:</p>
            {agreeWithTheModel ? (
              <>
                {' '}
                <img
                  className="w-8  object-cover"
                  src={
                    mitchPrediction.model_pred_moneyline?.moneyline?.team?.image
                  }
                  alt={
                    mitchPrediction.model_pred_moneyline?.moneyline?.team
                      ?.abbreviation
                  }
                />
                <span>
                  {
                    mitchPrediction.model_pred_moneyline?.moneyline?.team
                      ?.abbreviation
                  }{' '}
                  ({mitchPrediction.model_pred_moneyline?.moneyline?.odds})
                </span>
              </>
            ) : (
              <select
                name=""
                onChange={e =>
                  setMitchPrediction({
                    ...mitchPrediction,
                    predicted_winner: e.target.value,
                  })
                }
                className="border border-gray-400 py-2 px-4"
                value={mitchPrediction.predicted_winner}
                id=""
              >
                <option selected disabled value="">
                  Select
                </option>
                <option value={mitchPrediction.match?.hometeam?.name}>
                  {mitchPrediction.match?.hometeam?.name}
                </option>
                <option value={mitchPrediction.match?.awayteam?.name}>
                  {mitchPrediction.match?.awayteam?.name}
                </option>
              </select>
            )}
          </div>

          <div className="flex gap-4 pt-4 items-center focus:outline-none">
            <p>Score Diff:</p>
            {agreeWithTheModel ? (
              <p>{mitchPrediction.model_pred_moneyline?.score_diff}</p>
            ) : (
              <input
                placeholder="score difference"
                type="number"
                className="border border-gray-400 py-2 px-4"
                value={mitchPrediction.predicted_score_diff}
                onChange={e =>
                  setMitchPrediction({
                    ...mitchPrediction,
                    predicted_score_diff: e.target.value,
                  })
                }
              />
            )}
          </div>

          <div className="flex gap-4 pt-4 items-center focus:outline-none">
            <p>Comment:</p>
            {
              <textarea
                placeholder="comment..."
                className="border border-gray-400 py-2 px-4"
                value={mitchPrediction.comment}
                onChange={e =>
                  setMitchPrediction({
                    ...mitchPrediction,
                    comment: e.target.value,
                  })
                }
              />
            }
          </div>
        </div>

        <div className="pt-4 grid grid-cols-2 gap-2">
          <button
            onClick={() =>
              !agreeWithTheModel
                ? setShowMitchPredictionPopUp(false)
                : setAgreeWithTheModel(false)
            }
            className="text-md py-3 border border-gray-400"
          >
            {agreeWithTheModel ? 'Disagree' : 'Cancel'}
          </button>
          <button
            onClick={() => sendEmailHandler(agreeWithTheModel)}
            className={classNames(
              'text-md py-3 text-white border bg-blue-500',
              agreeWithTheModel &&
                !mitchPrediction.model_pred_moneyline?.moneyline?.team?.name
                ? 'opacity-50'
                : '',
            )}
            disabled={
              agreeWithTheModel &&
              !mitchPrediction.model_pred_moneyline?.moneyline?.team?.name
            }
          >
            {agreeWithTheModel
              ? 'Agree & Send Email'
              : 'Send Modified Prediction'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowMitchPredictionPopUp;
