export const GET_STARTING_PITCHERS_REQUEST = 'STARTING_PITCHERS_REQUEST';
export const GET_STARTING_PITCHERS_SUCCESS = 'STARTING_PITCHERS_SUCCESS';
export const GET_STARTING_PITCHERS_FAILURE = 'STARTING_PITCHERS_FAILURE';

export const GET_INJURIES_REQUEST = 'GET_INJURIES_REQUEST';
export const GET_INJURIES_SUCCESS = 'GET_INJURIES_SUCCESS';
export const GET_INJURIES_FAILURE = 'GET_INJURIES_FAILURE';

export const GET_ROSTER_REQUEST = 'GET_ROSTER_REQUEST';
export const GET_ROSTER_SUCCESS = 'GET_ROSTER_SUCCESS';
export const GET_ROSTER_FAILURE = 'GET_ROSTER_FAILURE';

export const GET_PAST_MATCHES_REQUEST = 'GET_PAST_MATCHES_REQUEST';
export const GET_PAST_MATCHES_SUCCESS = 'GET_PAST_MATCHES_SUCCESS';
export const GET_PAST_MATCHES_FAILURE = 'GET_PAST_MATCHES_FAILURE';

export const GET_HTH_PASTMATCHES_REQUEST = 'GET_HTH_PASTMATCHES_REQUEST';
export const GET_HTH_PASTMATCHES_SUCCESS = 'GET_HTH_PASTMATCHES_SUCCESS';
export const GET_HTH_PASTMATCHES_FAILURE = 'GET_HTH_PASTMATCHES_FAILURE';

export const GET_GPT_PREDICTION_REQUEST = 'GET_GPT_PREDICTION_REQUEST';
export const GET_GPT_PREDICTION_SUCCESS = 'GET_GPT_PREDICTION_SUCCESS';
export const GET_GPT_PREDICTION_FAILURE = 'GET_GPT_PREDICTION_FAILURE';

export const GET_PLAYER_STATS_REQUEST = 'GET_PLAYER_STATS_REQUEST';
export const GET_PLAYER_STATS_SUCCESS = 'GET_PLAYER_STATS_SUCCESS';
export const GET_PLAYER_STATS_FAILURE = ' GET_PLAYER_STATS_FAILURE';
