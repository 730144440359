import React, { useState, useEffect } from 'react';
import countryCodes from '../countrycodes.json'; // Import your country codes JSON
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../types/RootState';
import { toast } from 'react-toastify';
import { getRegisteredUserActions } from '../../../../redux/actions/RegisteredUserActions';
import axios from 'axios';

const RegisterUserForm = ({ setShowModal , roleValue }) => {
  const [formData, setFormData] = useState({
    role: '',
    name: '',
    email: ''
  });
  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState('');

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { loading } = useSelector((state: RootState) => state.registerUser);

  useEffect(() => {
    // Set default country code to +1 (United States)
    const defaultCountryCode = countryCodes.find((country) => country.name === 'United States');
    if (defaultCountryCode) {
      setCountryCode(defaultCountryCode.dial_code);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCountryChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  function isNumeric(str) {
    // The ^ and $ ensure the entire string is checked
    // The [0-9] ensures only digits are allowed
    const regex = /^[0-9]+$/;
    return regex.test(str);
  }

  function isAlphabetic(str) {
    // The ^ and $ ensure the entire string is checked
    // The [A-Za-z] ensures only alphabetic characters are allowed
    // The \s allows for spaces in between the characters
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(str);
  }

  const addNewUserHandler = async (e) => {

    if(isNumeric(phone) && isAlphabetic(formData?.name)){

      e.preventDefault();
      const newUser = formData.role === "CLIENT"
        ? { ...formData, countryCode, role: "ADMIN", mobile: phone, subRole: "INVESTOR" }
        : { ...formData, countryCode, role: "ADMIN", mobile: phone };
  
      const toastId = toast.loading("Adding User");
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL_2}/auth/register-email`,
          newUser,
        );
        if (response.data.success) {
          toast.dismiss(toastId);
          toast.success("User Added Successfully");
        } else {
          throw new Error("Failed to add user");
        }
      } catch (error) {
        toast.dismiss(toastId);
        toast.error("User Already Exists");
      }
  
      setFormData({ role: '', name: '', email: '' });
      const defaultCountryCode = countryCodes.find((country) => country.name === 'United States');
      if (defaultCountryCode) {
        setCountryCode(defaultCountryCode.dial_code);
      }
      setPhone('');
      setShowModal(false);
      dispatch(
        getRegisteredUserActions({ subRole:roleValue,currentPage, limit: limit }),
      );
    }else{
      toast.error("Mobile and Name with special characters is not valid")
    }
  };

  return (
    <div className="flex items-start mt-3 justify-center min-h-screen bg-gray-800">
      <div className="bg-gray-700 p-8 rounded-lg shadow-lg w-full max-w-md">
        <form onSubmit={addNewUserHandler}>
          <div className="mb-4">
            <label htmlFor="role" className="block text-sm font-medium text-gray-300">Role:</label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Role</option>
              <option value="CLIENT">Client</option>
              <option value="ADMIN">Admin</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              pattern="^[A-Za-z]+(\s[A-Za-z]+)*$"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="countryCode" className="block text-sm font-medium text-gray-300">Country Code:</label>
            <select
              id="countryCode"
              value={countryCode}
              onChange={handleCountryChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              {countryCodes.map((country) => (
                <option key={country.code} value={country.dial_code} selected={country.name === 'United States'}>
                  {country.name} ({country.dial_code})
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-300">Mobile:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              pattern='[0-9]{1,}'
              value={phone}
              onChange={handlePhoneChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          {!loading && (
            <button
              type="submit"
              className="w-full bg-blue-500 text-gray-200 py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
            >
              Add New User
            </button>
          )}
          {loading && (
            <div className="loader"></div>
          )}
          <button
            type="button"
            className="w-full bg-red-500 text-gray-200 py-2 px-4 rounded-md shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterUserForm;
