export function formatTime(s) {
  const inputDate = new Date(s);
  const now = new Date();

  // Check if the input date is today or tomorrow
  const isToday = inputDate.toDateString() === now.toDateString();
  const isTomorrow =
    inputDate.toDateString() ===
    new Date(now.getTime() + 24 * 60 * 60 * 1000).toDateString();

  // Formatting the time part
  let hours = inputDate.getHours();
  let minutes: any = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;

  if (isToday) {
    return 'Today, ' + strTime;
  } else if (isTomorrow) {
    return strTime;
  } else {
    // Formatting the date part for dates other than today or tomorrow
    const day = inputDate.getDate();
    const month = inputDate.toLocaleString('default', { month: 'short' });
    const year = inputDate.getFullYear();
    return `${strTime}`;
  }
}

export function formatDate(s) {
  const inputDate = new Date(s);
  const now = new Date();

  // Check if the input date is today or tomorrow
  const isToday = inputDate.toDateString() === now.toDateString();
  const isTomorrow =
    inputDate.toDateString() ===
    new Date(now.getTime() + 24 * 60 * 60 * 1000).toDateString();

  // Formatting the time part
  let hours = inputDate.getHours();
  let minutes: any = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;

  // Formatting the date part for dates other than today or tomorrow
  const day = inputDate.getDate();
  const month = inputDate.toLocaleString('default', { month: 'short' });
  const year = inputDate.getFullYear();
  return `${day} ${month}, ${year}`;
}
