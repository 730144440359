// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Vector.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customArrow {\n  -moz-padding-start: calc(0.75rem - 3px);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  /* background-position: right 0.4rem center; */\n  background-size: 10px 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/admincomponents/pagination/style/custom.pagination.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,yDAAqC;EACrC,8CAA8C;EAC9C,yBAAyB;AAC3B","sourcesContent":[".customArrow {\n  -moz-padding-start: calc(0.75rem - 3px);\n  background-image: url('./Vector.svg');\n  /* background-position: right 0.4rem center; */\n  background-size: 10px 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
