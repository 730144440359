import React, { useEffect, useState, useRef } from 'react';
import downarr from 'assets/images/SinglePlayerDetails/downarr.svg';
// import { user, User } from './dropdownarr';

const UserdropdownMatchType = ({ selected, setSelected }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const options = ['MLB', 'NFL']; // List of available options

  const handleOptionClick = (name: string) => {
    if (name !== selected) {
      setSelected(name);
    }
    setOpen(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div
      className="w-40 px-1 h-10 text-paragraph font-bold text-sm outline-0 border-2 border-borderadmin rounded-lg z-20"
      ref={dropdownRef}
    >
      {/* <div
        onClick={() => setOpen(!open)}
        className={` w-full px-4 py-2 text-sm flex items-center justify-between ${
          selected !== 'Active User' ? 'text-gray-700' : ''
        }`}
      >
        {selected.length > 25 ? selected.substring(0, 25) + '...' : selected}
        <div className={`${open ? 'rotate-180' : ''}`}>
          <img src={downarr} alt="Dropdown arrow" />
        </div>
      </div>
      <ul
        className={`bg-white overflow-y-auto border-[1px]  ${
          open ? 'max-h-60' : 'max-h-0'
        }`}
      >
        {options
          .filter(option => option !== selected)
          .map((option, index) => (
            <li
              key={index}
              className={`px-4 py-3 text-sm text-paragraph`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
      </ul> */}

      <select
        name=""
        className="w-full focus:outline-none h-full rounded-lg "
        id=""
        value={selected}
        onChange={e => setSelected(e.target.value)}
      >
        {options.map(o => {
          return <option value={o}>{o}</option>;
        })}
      </select>
    </div>
  );
};

export default UserdropdownMatchType;
