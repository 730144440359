import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { getPredictionStatsApi } from '../../api/Admin/stats';

import {
  GET_PREDICTION_STATS_FAILURE,
  GET_PREDICTION_STATS_REQUEST,
  GET_PREDICTION_STATS_SUCCESS,
} from '../constants/StatsConstants';

export const getPredictionStatsAction =
  ({ sport }) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({
        type: GET_PREDICTION_STATS_REQUEST,
      });

      const { data } = await getPredictionStatsApi({ sport });

      dispatch({
        type: GET_PREDICTION_STATS_SUCCESS,
        payload: data.payload,
      });
    } catch (error: any) {
      dispatch({
        type: GET_PREDICTION_STATS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
