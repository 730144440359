import React, { useEffect, useState } from 'react';
import TypeBox from './TypeBox';
import { X } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import axios from 'axios';
import TextEditor from './TextEditor';
interface ValidationErrors {
  sendMethod?: string;
  textMode?: string;
  emailList?: string;
  groupList?: string;
  subject?: string;
  textBody?: string;
}
interface Errors {
  sendMethod?: string;
  textMode?: string;
  emailList?: string;
  groupList?: string;
  subject?: string;
  textBody?: string;
}

const CustomEmail = () => {
  const [formData, setFormData] = useState({
    sendEmail: false,
    sendSms: false,
    textMode: '',
    subject: '',
    textBody: '',
  });

  const [emailList, setEmailList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});



  // const clearText = (clearContent)=>{
  //   clearContent("");
  // }

  const changeHandler = (event) => {
    const { name, type, checked, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const removeHandler = (item) => {
    if (formData?.textMode === "Email") {
      setEmailList((prev) => prev.filter((mails) => mails !== item));
    } else {
      setGroupList((prev) => prev.filter((group) => group !== item));
    }
  };

  const validateForm = () => {
    const errors: Errors = {};
    if (!formData.sendEmail && !formData.sendSms) {
      errors.sendMethod = "Please select at least one method (Email or SMS)";
    }
    if (!formData.textMode) {
      errors.textMode = "Please select a sending mode (Email Address or Group)";
    }
    if (formData.textMode === "Email" && emailList.length === 0) {
      errors.emailList = "Please add at least one email address";
    }
    if (formData.textMode === "Group" && groupList.length === 0) {
      errors.groupList = "Please add at least one group";
    }
    if (!formData.subject.trim()) {
      errors.subject = "Please enter a subject";
    }
    if (!formData.textBody.trim()) {
      errors.textBody = "Please enter the email body/message";
    }
    return errors;
  };


  const clearFieldsHandler = ()=>{
    setFormData({
      sendEmail: false,
      sendSms: false,
      textMode: '',
      subject: '',
      textBody: '',
    })
    setEmailList([]);
    setGroupList([]);
  }

  const sendHandler = async () => {
    console.log("inside");
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }


    if(formData?.textMode=="Email"){

      setValidationErrors({});
      const toastId = toast.loading("Sending Mails");
      
      const tokenString = localStorage.getItem("token");
      
      try {
        if (formData.sendEmail && formData.textMode === 'Email' && emailList.length !== 0) {
          
          if(tokenString){
            const tokenObject = JSON.parse(tokenString);
            const token = tokenObject.token;
            const response = await axios.post(`${process.env.REACT_APP_API_URL_2}/mail/sendmail`, {
              mailList: emailList,
              subject: formData.subject,
              body: formData.textBody,
            },{
              headers: {
              Authorization: `Bearer ${token}`
              }
          });
    
            if (response?.data?.success) {
              toast.dismiss(toastId);
              toast.success("Mails sent successfully");
              clearFieldsHandler();
            } else {
              throw new Error("Mail sending failed");
            }
          }  
  
        }
  
        if (formData.sendSms && formData.textMode === 'Email' && emailList.length !== 0) {
          // Call send SMS API with this body and subject to all phones associated with the users
          toast.dismiss(toastId);
          toast.success("SMS sent successfully");
        }
      } catch (error) {
        toast.dismiss(toastId);
        toast.error("Cannot send mails/SMS at the moment");
        console.error(error);
      }
    }else if(formData?.textMode=="Group"){
      setValidationErrors({});
      const toastId = toast.loading("Sending Mails");
      const tokenString = localStorage.getItem("token");
      console.log("we are using send groups api")
      try {
        if (formData.sendEmail && formData.textMode === 'Group' && groupList.length !== 0) {
          
          if(tokenString){
            const tokenObject = JSON.parse(tokenString);
            const token = tokenObject.token;
            const response = await axios.post(`${process.env.REACT_APP_API_URL_2}/mail/sendmail-to-groups`, {
              group: groupList,
              subject: formData.subject,
              body: formData.textBody,
            },{
              headers: {
              Authorization: `Bearer ${token}`
              }
          });
    
            if (response?.data?.success) {
              toast.dismiss(toastId);
              toast.success("Mails sent to groups successfully");
            } else {
              throw new Error("Mail sending failed");
            }
          }  
  
        }
  
      } catch (error) {
        toast.dismiss(toastId);
        toast.error("Cannot send mails/SMS at the moment");
        console.error(error);
      }
    }




  };

  useEffect(() => {
    if (formData.textMode === "Email") {
      setGroupList([]);
    } else {
      setEmailList([]);
    }
  }, [formData.textMode]);

  return (
    <div className='w-full h-full py-2'>
      <div className='flex bg-white w-full px-2 py-4 rounded-lg flex-row gap-3'>
        <div className='flex gap-2 flex-row'>
          <label htmlFor="sendEmail" className='text-md '>Email</label>
          <input type="checkbox" id="sendEmail" name='sendEmail' checked={formData.sendEmail} onChange={changeHandler} />
        </div>
        <div className='flex gap-2 flex-row'>
          <label htmlFor='sendSms' className='text-md '>SMS</label>
          <input type="checkbox" id="sendSms" name='sendSms' checked={formData.sendSms} onChange={changeHandler} />
        </div>
      </div>
      {validationErrors.sendMethod && <span className='text-red-500'>{validationErrors.sendMethod}</span>}

      <div className='flex bg-white w-full px-2 py-4 rounded-lg flex-row gap-3 mt-3'>
        <div className='flex flex-row gap-2'>
          <span>Send To :</span>
          <div className='flex flex-row gap-2'>
            <div className='flex gap-2'>
              <input type="radio" id="Email" name="textMode" value="Email" onChange={changeHandler}  checked={formData?.textMode=="Email"}  />
              <label htmlFor='Email'>Email Address</label>
            </div>
            <div className='flex gap-2'>
              <input type="radio" id="Group" name="textMode" value="Group" onChange={changeHandler}  checked={formData?.textMode=="Group"} />
              <label htmlFor='Group'>Group</label>
            </div>
          </div>
        </div>
      </div>
      {validationErrors.textMode && <span className='text-red-500'>{validationErrors.textMode}</span>}

      {formData.textMode === "Email" && (
        <div className='flex bg-white w-full px-2 py-4 rounded-lg flex-row gap-3 mt-3'>
          <TypeBox isEmail={true} setEmailList={setEmailList} emailList={emailList} groupList={groupList} setGroupList={setGroupList} textMode = {formData?.textMode}  />
        </div>
      )}
      {formData.textMode === "Group" && (
        <div className='flex bg-white w-full px-2 py-4 rounded-lg flex-row gap-3 mt-3'>
          <TypeBox isEmail={false} setEmailList={setEmailList} emailList={emailList} groupList={groupList} setGroupList={setGroupList} textMode = {formData?.textMode} />
        </div>
      )}
      {validationErrors.emailList && <span className='text-red-500'>{validationErrors.emailList}</span>}
      {validationErrors.groupList && <span className='text-red-500'>{validationErrors.groupList}</span>}

      {emailList.length !== 0 && <span className='mt-3 text-green-400'>*Mails Added</span>}
      {emailList.length !== 0 && (
        <div className='flex bg-white w-full px-2 py-4 rounded-lg lg:flex-row  md:flex-row flex-col flex-wrap gap-3 mt-1  sm:overflow-y-scroll'>
          {emailList.map((item, index) => (
            <div key={index}>
              <div className='flex flex-row  w-fit gap-2 bg-blue-200 text-black rounded-md px-2 py-2'>
                <span>{item}</span>
                <X size={24} onClick={() => { removeHandler(item) }} />
              </div>
            </div>
          ))}
        </div>
      )}
      {groupList.length !== 0 && <span className='mt-3 text-green-400'>*Groups Added</span>}
      {groupList.length !== 0 && (
        <div className='flex bg-white w-full px-2 py-4 rounded-lg lg:flex-row  md:flex-row flex-col flex-wrap gap-3 mt-1  sm:overflow-y-scroll'>
          {groupList.map((item, index) => (
            <div key={index}>
              <div className='flex flex-row w-fit gap-2 bg-blue-200 text-black rounded-md px-2 py-2'>
                <span>{item}</span>
                <X size={24} onClick={() => { removeHandler(item) }} />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className='flex bg-white px-2 py-4 w-full rounded-lg flex-row gap-3 mt-3'>
        <input type="text" name='subject' className='w-full focus:outline-none' placeholder='Subject' value={formData.subject} onChange={changeHandler} />
      </div>
      {validationErrors.subject && <span className='text-red-500'>{validationErrors.subject}</span>}

      {/* <div className='flex bg-white px-2 py-4 w-full rounded-lg flex-row gap-3 mt-3'>
        <textarea rows={10} className='w-full focus:outline-none' name='textBody' placeholder='Email Body/Message' value={formData.textBody} onChange={changeHandler} />
      </div> */}
      <div className='flex bg-white px-2 py-4 w-full rounded-lg flex-row gap-3 mt-3'>
      <TextEditor setFormData={setFormData} formData={formData}/>
      </div>
      {validationErrors.textBody && <span className='text-red-500'>{validationErrors.textBody}</span>}

      <div className='mt-3'>
        <button className='bg-blue-500 px-3 py-4 rounded-lg text-white' onClick={sendHandler}>Send Mail</button>
      </div>
    </div>
  );
};

export default CustomEmail;
