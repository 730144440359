import React, { useEffect, useState } from 'react';
import MatchCard from './comps/MatchCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSituationalMatchesActions } from '../../../redux/actions/SituationalActions';
import { RootState } from '../../../types';
import Loading from '../../../libs/Loading';
import Pagination from '../pagination/Pagination';

export function formatDateTime(s) {
  const inputDate = new Date(s);
  const now = new Date();

  // Check if the input date is today or tomorrow
  const isToday = inputDate.toDateString() === now.toDateString();
  const isTomorrow =
    inputDate.toDateString() ===
    new Date(now.getTime() + 24 * 60 * 60 * 1000).toDateString();

  // Formatting the time part
  let hours = inputDate.getHours();
  let minutes: any = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;

  if (isToday) {
    return 'Today, ' + strTime;
  } else if (isTomorrow) {
    return 'Tmw, ' + strTime;
  } else {
    // Formatting the date part for dates other than today or tomorrow
    const day = inputDate.getDate();
    const month = inputDate.toLocaleString('default', { month: 'short' });
    const year = inputDate.getFullYear();
    return `${day} ${month}, ${strTime}`;
  }
}
const Situations = () => {
  const dispatch = useDispatch();
  const { data, loading, query } = useSelector(
    (state: RootState) => state.getSituationalMatches,
  );

  const [currentPage, setCurrentPage] = useState(query?.page ?? 1);
  const [limit, setLimit] = useState(query?.limit ?? 20);

  useEffect(() => {
    if (
      JSON.stringify(query) !==
      JSON.stringify({ page: currentPage, limit: limit })
    )
      dispatch(
        getSituationalMatchesActions({ page: currentPage, limit: limit }),
      );
  }, [limit, currentPage]);

  return (
    <>
      <div className="w-full h-full mb-64">
        <div className="filter px-8  pt-10 w-full h-full md:gap-x-8">
          <div className="w-full rounded-lg bg-white h-full pt-7 pb-9 flex flex-col">
            <div className="flex items-center justify-between">
              <div className="flex gap-x-2">
                <div className="w-2 h-11 bg-bgblue"></div>
                <div className="bg-bgfadeblack text-white rounded-r-full px-4 py-3">
                  <p className="team para font-bold text-sm">
                    Situation Matches
                  </p>
                </div>
              </div>

              <div className="pr-5">
                {data && (
                  <Pagination
                    onLimitChnage={e => setLimit(e.target.value)}
                    setPage={setCurrentPage}
                    currentPage={currentPage}
                    totalCount={data?.totalCount}
                    limit={limit}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            {loading ? (
              <div className="flex justify-center">
                <Loading />
              </div>
            ) : (
              <div className="grid lg:grid-cols-4 md:grid-cols-2  gap-4 mt-4">
                {data?.data?.map((s, index) => {
                  return (
                    <Link className="cusor-pointer" to={s.match_id._id}>
                      <MatchCard
                        timings={formatDateTime(s.match_id?.datetimeUTC)}
                        key={index}
                        totalPoints={s?.situationCount}
                        markedPoints={s?.situationCount - s?.nonMarkedCount}
                        team1={s?.match_id?.hometeam?.name}
                        team2={s?.match_id?.awayteam?.name}
                        logo1={s?.match_id?.hometeam?.image}
                        logo2={s?.match_id?.awayteam?.image}
                      />
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Situations;
