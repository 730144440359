/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';
import {
  getDownloadPromptByMatchIdReducer,
  getMatchScheduleforDateReducer,
  getNonPredictedMatchesForDateReducer,
  getPredictionForDateReducer,
  getRefreshDataReducer,
  getRunPredictionFromPromptIdReducer,
} from '../redux/reducers/DashboardReducer';
import {
  getGPTpredictionReducer,
  getHTHpastMatchesReducer,
  getInjuriesReducer,
  getPastMatchesWithTeamIdReducer,
  getPlayerStatsReducer,
  getRosterReducer,
  getstartingPitchersReducer,
} from '../redux/reducers/SidebarReducer';
import { getPredictionStatsReducer } from '../redux/reducers/StatsReducer';
import {
  getSituationalMatchesReducer,
  getSituationalPointsOfAMatchReducer,
} from '../redux/reducers/SituationalReducer';
import {getRegisteredUserReducer,
  registerUserReducer} from "../redux/reducers/RegisterUsersReducer"
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  // if (Object.keys(injectedReducers).length === 0) {
  //   return state => state;
  // } else {
  return combineReducers({
    ...injectedReducers,
    getMatchScheduleforDate: getMatchScheduleforDateReducer,
    getPredictionForDate: getPredictionForDateReducer,
    getRunPredictionFromPromptId: getRunPredictionFromPromptIdReducer,
    getRefreshData: getRefreshDataReducer,
    getDownloadPromptByMatchId: getDownloadPromptByMatchIdReducer,
    getPredictionStats: getPredictionStatsReducer,
    getNonPredictedMatchesForDate: getNonPredictedMatchesForDateReducer,

    // <---------------------sidebar ------------------------------>
    getstartingPitchers: getstartingPitchersReducer,
    getInjuries: getInjuriesReducer,
    getRoster: getRosterReducer,
    getPastMatchesWithTeamId: getPastMatchesWithTeamIdReducer,
    getHTHpastMatches: getHTHpastMatchesReducer,
    getGPTprediction: getGPTpredictionReducer,
    getPlayerStats: getPlayerStatsReducer,

    getSituationalMatches: getSituationalMatchesReducer,
    getSituationalPointsOfAMatch: getSituationalPointsOfAMatchReducer,

    // <--------------------Users----------------------------------->
    getRegisteredUser:getRegisteredUserReducer,
    registerUser : registerUserReducer
  });
  // }
}
