import React,{useState} from 'react'
import CustomEmail from "./comps/CustomEmail"
import PredictionEmail from "./comps/PredcitionEmail"
const Email = () => {

  const [custom,setCustom] = useState(true);
  
  return (
    <div className='w-full h-full '>

           <div className='flex flex-row mt-3 w-11/12 bg-white mx-auto rounded-lg px-2 py-4 gap-2'> 

              <button className={`px-2 py-2  font-bold text-white rounded-md ${custom?"bg-blue-600":"bg-blue-500"} `} onClick={()=>setCustom(true)}>Custom</button>
              <button className={`px-2 py-2 bg-blue-500 font-bold text-white rounded-md ${!custom?"bg-blue-600":"bg-blue-500"}`} onClick={()=>setCustom(false)}>Predictions</button>
            </div> 

          
          {custom&&<div className='mt-3 w-11/12 mx-auto rounded-lg  gap-2'>
              <CustomEmail/>
            </div>
          }
          {
            !custom&&<div className='mt-3 w-11/12 mx-auto rounded-lg  gap-2'>
              <PredictionEmail/>
            </div>
          }


    </div>
  )
}

export default Email