import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
  getSituationalMatches,
  getSituationalPointsOfAMatch,
} from '../../api/Admin/situationalData';
import {
  GET_SITUATIONAL_MATCHES_FAILURE,
  GET_SITUATIONAL_MATCHES_REQUEST,
  GET_SITUATIONAL_MATCHES_SUCCESS,
  GET_SITUATIONAL_POINTS_OF_A_MATCH_FAILURE,
  GET_SITUATIONAL_POINTS_OF_A_MATCH_REQUEST,
  GET_SITUATIONAL_POINTS_OF_A_MATCH_SUCCESS,
} from '../constants/SituationalConsts';

export const getSituationalMatchesActions =
  ({ page, limit }) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({
        type: GET_SITUATIONAL_MATCHES_REQUEST,
      });

      const { data } = await getSituationalMatches({ page, limit });

      dispatch({
        type: GET_SITUATIONAL_MATCHES_SUCCESS,
        payload: data?.payload,
        query: { page, limit },
      });
    } catch (error: any) {
      dispatch({
        type: GET_SITUATIONAL_MATCHES_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSituationalPointsOfAMatchActions =
  ({ match_id }) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({
        type: GET_SITUATIONAL_POINTS_OF_A_MATCH_REQUEST,
        match_id: match_id,
      });

      const { data } = await getSituationalPointsOfAMatch({ match_id });

      dispatch({
        type: GET_SITUATIONAL_POINTS_OF_A_MATCH_SUCCESS,
        payload: data?.payload?.data,
        match_id: match_id,
      });
    } catch (error: any) {
      dispatch({
        type: GET_SITUATIONAL_POINTS_OF_A_MATCH_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
