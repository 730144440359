/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { LoginPage } from './pages/Login';
import { SignUpPage } from './pages/SignUp';
import { ResetPasswordPage } from './pages/ResetPassword';
import { RecoverPasswordPage } from './pages/RecoverPassword';
import { VerifyEmailPage } from './pages/VerifyEmail';
import { NFlPage } from './pages/NFl';
import { NflDetailsPage } from './pages/NflDetailsPage';
import { AdminPage } from './pages/AdminPage';
import { AdminLoginPage } from './pages/AdminLogin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="SportsPulse AI"
        defaultTitle="SportsPulse AI"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A SportsPulse AI application" />
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Routes>
        <Route path="/*" element={<AdminPage />} />
        <Route path="/admin/*" element={<Navigate to={'/'} />} />
        <Route path="/login/*" element={<AdminLoginPage />} />
        {/* <Route path="/old-home/*" element={<HomePage />} />
        <Route path="/old-home/login/*" element={<LoginPage />} />
        <Route path="/signup/*" element={<SignUpPage />} />
        <Route path="/emailconfirmation/*" element={<VerifyEmailPage />} />
        <Route path="/resetpassword/*" element={<ResetPasswordPage />} />0
        <Route path="/recoverpassword/*" element={<RecoverPasswordPage />} />
        <Route path="*" element={<NotFoundPage />} /> */}
        {/* <Route path="/nfldetails/*" element={<NflDetailsPage />} /> */}
        {/* <Route path="/nfl/*" element={<NFlPage />} /> */}
        {/* <Route path="/nfldetails/*" element={<NflDetailsPage />} /> */}
        {/* <----------------- ADMIN PART -------------------------------> */}
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
