import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { toast } from "react-toastify";
import Loading from '../../../../libs/Loading';

const mailData = [
    "john.doe@example.com",
    "jane.smith@example.com",
    "alice.jones@example.com",
    "bob.brown@example.com",
    "carol.white@example.com",
    "david.black@example.com",
    "eve.green@example.com",
    "frank.harris@example.com",
    "grace.miller@example.com",
    "henry.moore@example.com"
];

const groupData = [
    "admin",
    "client"
];

const TypeBox = ({ isEmail, emailList, setEmailList, groupList, setGroupList, textMode }) => {
    const dummyData = (textMode === "Group" ? groupData : []);
    const debounceRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [showTypeBox, setShowTypeBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [typeBoxData, setTypeBoxData] = useState(dummyData);
    const [mail, setMail] = useState("");
    const tokenString = localStorage.getItem("token");
    const changeHandler = (value: string) => {
        setMail(value);

        if (value === "") {
            setTypeBoxData(dummyData);
        } else {
            setTypeBoxData(dummyData.filter((item) => item.includes(value)));
        }
    };

    const debounceApiCall = async (value: string) => {
        


        if(tokenString){
            const tokenObject = JSON.parse(tokenString);
            const token = tokenObject.token;

            setLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL_2}/mail/emails`, {
                    email: value
                }, {
                    headers: {
                    Authorization: `Bearer ${token}`
                    }
                });
                if (response?.data?.success === true) {
                    setTypeBoxData(response?.data?.payload?.response?.emailList);
                }
            } catch (error) {
                toast.error("Error while fetching emails");
            } finally {
                setLoading(false);
            }
        }
    };

    const debounce = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        setMail(value);

        
        debounceRef.current = setTimeout(() => debounceApiCall(value), 500);
        
    };

    const typeBoxHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        const { innerText } = event.target as HTMLElement;
        setMail(innerText);
    };

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            addHandler();
        }
    };

    const addHandler = () => {
        if (textMode=="Email") {
            if (!emailList.includes(mail)) {
                setEmailList((prev) => [...prev, mail]);
            }
        } 

        if (textMode=="Group"){
            if (!groupList.includes(mail)) {
                setGroupList((prev) => [...prev, mail]);
            }
        }
    
        setMail("");
    };

    useEffect(() => {
        if (textMode === "Email") {
            if (mail === "" || mail.length > 2) {
                debounceApiCall(mail);
            }
        }
    }, []);

    return (
        <div className='flex flex-row gap-2 w-full'>
            <div className='flex flex-row w-max'><span>To :</span></div>
            <div className='relative grow'>
                <input
                    className='w-full focus:outline-none'
                    list='emailList'
                    name='emailAddress'
                    value={mail}
                    type="text"
                    onChange={debounce}
                    placeholder={isEmail ? "Type/Select Mail" : "Type/Select Group"}
                    onFocus={() => setShowTypeBox(true)}
                    onBlur={() => {
                        setTimeout(() => setShowTypeBox(false), 150);
                    }}
                    onKeyDown={keyDownHandler}
                />
                {showTypeBox && (
                    <div className='absolute w-full bg-white rounded-lg border-2 py-2 px-3 max-h-56 overflow-y-auto top-8 z-10'>
                        {!loading && typeBoxData?.map((item, index) => (
                            <div
                                key={index}
                                onClick={typeBoxHandler}
                                className='w-full text-lg mb-1  bg-white border-b-2 px-1 cursor-pointer py-1'>
                                {item}
                            </div>
                        ))}
                        {loading && <Loading />}
                    </div>
                )}
            </div>
            <button
                className='bg-blue px-2 py-2 bg-blue-500 text-white text-lg rounded-lg w-24'
                onClick={addHandler}
                disabled={mail.length === 0}>
                Add
            </button>
        </div>
    );
};

export default TypeBox;
