import { AnyAction } from '@reduxjs/toolkit';
import {
  GET_SITUATIONAL_MATCHES_FAILURE,
  GET_SITUATIONAL_MATCHES_REQUEST,
  GET_SITUATIONAL_MATCHES_SUCCESS,
  GET_SITUATIONAL_POINTS_OF_A_MATCH_FAILURE,
  GET_SITUATIONAL_POINTS_OF_A_MATCH_REQUEST,
  GET_SITUATIONAL_POINTS_OF_A_MATCH_SUCCESS,
} from '../constants/SituationalConsts';

export const getSituationalMatchesReducer = (
  state = {
    data: null,
    error: null,
    loading: false,
    query: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_SITUATIONAL_MATCHES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SITUATIONAL_MATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        query: action.query,
        error: null,
      };
    case GET_SITUATIONAL_MATCHES_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getSituationalPointsOfAMatchReducer = (
  state = {
    data: null,
    error: null,
    loading: false,
    match_id: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_SITUATIONAL_POINTS_OF_A_MATCH_REQUEST:
      return {
        ...state,
        loading: state.match_id !== action.match_id,
        error: null,
      };
    case GET_SITUATIONAL_POINTS_OF_A_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        match_id: action.match_id,
      };
    case GET_SITUATIONAL_POINTS_OF_A_MATCH_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};
