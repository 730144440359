import { AnyAction } from '@reduxjs/toolkit';
import {
  GET_PREDICTION_STATS_FAILURE,
  GET_PREDICTION_STATS_REQUEST,
  GET_PREDICTION_STATS_SUCCESS,
} from '../constants/StatsConstants';

export const getPredictionStatsReducer = (
  state = {
    data: null,
    error: null,
    loading: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_PREDICTION_STATS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PREDICTION_STATS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_PREDICTION_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};
