import React, { useEffect, useState } from 'react';
import {
  sendMlpSelections,
  updatePredictionMatches,
} from '../../../api/Admin/Predictions';
import { formatDateTime } from '../Situations';
import { toast } from 'react-toastify';
import Loading from '../../../libs/Loading';
import classNames from 'classnames';
import WoWooTabel from './comps/WoWooTabel';
import { formatDate } from './functions';
import {
  ArrowsCounterClockwise,
  CellSignalHigh,
  Circle,
  CurrencyDollar,
  Envelope,
  EnvelopeOpen,
  Gear,
  Plus,
  Ticket,
  X,
} from '@phosphor-icons/react';
import ShowMitchPredictionPopUp from './comps/ShowMitchPredictionPopUp';
import { americanToPct } from '../../../utils/formatOdds';
import { Link } from 'react-router-dom';

const CreatePrediction = ({ type, tobematches, loading, getPred }) => {
  const sports = ['NBA'];
  const [selectedSport, setSelectedSport] = useState(['NBA']);

  const [loadingPrediction, setLoadingPrediction] = useState(false);

  const [matchIdsStr, setMatchIdsStr] = useState('');
  const [match_ids, setmatch_ids] = useState('');
  useEffect(() => {
    const match_ids_arr = tobematches
      ?.filter(
        s => s.match_id.sport === type && s.match_id.status === 'scheduled',
      )
      ?.map(m => {
        return m.match_id._id;
      });
    setmatch_ids(match_ids_arr);
    let str = match_ids_arr?.join(',');
    const match_ids_started = tobematches
      ?.filter(
        s => s.match_id.sport === type && s.match_id.status !== 'scheduled',
      )
      ?.map(m => {
        return m.match_id._id;
      });

    setMatchIdsStr(str);
  }, [type, tobematches]);

  const predictHandler = async mids => {
    try {
      toast.loading('Creating Prediction.');
      setLoadingPrediction(true);

      await updatePredictionMatches({ matchIdsStr: mids, sport: type });
      await getPred();
      toast.dismiss();
      toast.success('Prediction Created');
      setLoadingPrediction(false);
    } catch (error) {
      toast.dismiss();
      setLoadingPrediction(false);

      toast.error('some error occured.');
    }
  };

  const sendMlpSelection = async data => {
    try {
      toast.loading('Sending MLP Selection...');
      setLoadingPrediction(true);

      await sendMlpSelections(data);
      setShowMitchPredictionPopUp(false);

      await getPred();
      toast.dismiss();
      toast.success('MLP selection saved and email sent');
      setLoadingPrediction(false);
    } catch (error) {
      toast.dismiss();
      setLoadingPrediction(false);

      toast.error('some error occured.');
    }
  };

  const sendEmailHandler = async agreeWithTheModel => {
    const moneylineData = [
      {
        match_id: mitchPrediction?.match?._id,
        winner: mitchPrediction.model_pred_moneyline?.moneyline?.team?.name,
        score_diff: mitchPrediction.model_pred_moneyline?.score_diff,
        comment: mitchPrediction.comment ?? '',
      },
    ];

    const mitchData = [
      {
        match_id: mitchPrediction?.match?._id,
        winner: mitchPrediction.predicted_winner,
        score_diff: mitchPrediction.predicted_score_diff,
        comment: mitchPrediction.comment,
      },
    ];
    const data = agreeWithTheModel ? moneylineData : mitchData;

    await sendMlpSelection({ mlpSelections: data });
    setShowMitchPredictionPopUp(false);
  };

  const sendMultipleSelectionsEmailHandler = async () => {
    await sendMlpSelection({ mlpSelections: multipleMLPSelections });
    setShowSendAllPredictionsMitch(false);
    setMultipleMLPSelections([]);
    setSelectedMatches([]);
  };

  const [showMitchPredictionPopUp, setShowMitchPredictionPopUp] =
    useState(false);

  const [mitchPrediction, setMitchPrediction] = useState<any>({
    match: null,
    predicted_winner: '',
    model_pred_moneyline: null,
    predicted_score_diff: null,
    comment: '',
  });

  const [agreeWithTheModel, setAgreeWithTheModel] = useState(true);

  const [selectedMatches, setSelectedMatches] = useState<any>([]);

  const [showSendAllPredictionsMitch, setShowSendAllPredictionsMitch] =
    useState(false);

  const [multipleMLPSelections, setMultipleMLPSelections] = useState<any>();

  useEffect(() => {
    const mlpSelections = selectedMatches?.map(m => {
      const nm2 = m?.nm2?.prediction;
      const mm1 = m?.mlb?.mm1?.prediction;

      const isPredicted = type === 'BASEBALL' ? mm1 : nm2;
      return {
        match_id: m.match_id._id,
        winner: isPredicted?.moneyline?.team?.name,
        score_diff: isPredicted.score_diff,
        comment: '',
      };
    });

    if (mlpSelections) setMultipleMLPSelections([...mlpSelections]);
  }, [selectedMatches]);

  const [showDropdowns, setShowDropdowns] = useState<any>([]);

  return (
    <>
      <div className="w-full min-w-[1500px] h-full mb-10  md:block hidden">
        <div className="filter  flex  w-full overflow-x-auto md:gap-x-8">
          <div className="flex flex-col w-full bg-white rounded-b-lg  pt-7 px-8 pb-9">
            <div className=" flex gap-5 items-center justify-between">
              <div className="flex gap-5 items-center">
                <div className="flex">
                  <p className="font-medium">
                    Date:{' '}
                    {tobematches &&
                      tobematches.length &&
                      formatDate(tobematches[0].date)}
                  </p>
                </div>
              </div>

              {/* <div className="flex items-center gap-2 justify-center">
                <div className="flex gap-4">
                  <p>w/o : With Odds</p>
                  <div className="h-6 w-0.5 bg-black"></div>
                  <p>wo/o : Without Odds</p>
                  <div className="h-6 w-0.5 bg-black"></div>
                  <p>m/s : MLP Selection</p>
                </div>
              </div> */}

              <div className="flex gap-5">
                {/*<Link to={'/create-parlay'}>*/}
                {/*  <button*/}
                {/*    disabled={loading || loadingPrediction}*/}
                {/*    className={classNames(*/}
                {/*      'rounded-lg border-bgblue border-2 px-5 py-[10px] ml-2 cursor-pointer',*/}
                {/*      (loading || loadingPrediction) && 'opacity-50',*/}
                {/*    )}*/}
                {/*  >*/}
                {/*    <p className="font-bold  text-sm">Create Parlay Bet</p>*/}
                {/*  </button>*/}
                {/*</Link>*/}

                <button
                  disabled={loading || loadingPrediction}
                  className={classNames(
                    'rounded-lg bg-bgblue px-5 py-[10px] ml-2 cursor-pointer',
                    (loading || loadingPrediction) && 'opacity-50',
                  )}
                  onClick={() => predictHandler(match_ids)}
                >
                  <p className="font-bold text-white text-sm">
                    Run all prediction for date
                  </p>
                </button>

                <button
                  disabled={
                    loading || loadingPrediction || !selectedMatches.length
                  }
                  className={classNames(
                    'rounded-lg bg-violet-500 px-5 py-[10px] ml-2 cursor-pointer',
                    (loading || loadingPrediction || !selectedMatches.length) &&
                      'opacity-50',
                  )}
                  onClick={() => setShowSendAllPredictionsMitch(true)}
                >
                  <p className="font-bold text-white text-sm">
                    Send Selected MLP Selection
                  </p>
                </button>
              </div>
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div className="mt-6">
                <table className="border-collapse text-sm flex-1 border border-bordercheckbox mt-4 w-full">
                  <thead>
                    <tr className="text-bgparagraph opacity-[0.5] font-bold">
                      <th className="border border-bordergrey p-2 bg-bggreyfade  py-[10px]">
                        <input
                          checked={
                            selectedMatches.length === tobematches?.length
                          }
                          onChange={() => {
                            if (tobematches) {
                              if (
                                selectedMatches.length !== tobematches?.length
                              ) {
                                setSelectedMatches([...tobematches]);
                              } else {
                                setSelectedMatches([]);
                              }
                            }
                          }}
                          type="checkbox"
                          className="w-full cursor-pointer"
                        />
                      </th>
                      <th className="border border-bordergrey p-2 bg-bggreyfade  py-[10px]">
                        <div className="flex justify-start px-4">Date</div>
                      </th>
                      <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                        Match
                      </th>
                      <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                        Predicted Winner
                      </th>
                      <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                        PSD
                      </th>
                      {/* <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                        Spread Prediction
                      </th> */}
                      <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                        Bet Type
                      </th>
                      {type === 'BASKETBALL' && (
                        <>
                          <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                            Value
                          </th>
                          <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                            Rev. Value
                          </th>
                        </>
                      )}
                      <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {tobematches
                      ?.filter(s => s.sport === type)
                      ?.map((m, index) => {
                        const moneyLineCurrent = m?.odds?.lines?.filter(
                          s => s.name === 'moneyline_current',
                        )[0]?.outcomes;

                        const scraped_odds = m?.scraped_odds;

                        const home_team_odds = moneyLineCurrent
                          ? moneyLineCurrent[0]?.odds
                          : scraped_odds?.home_odds;
                        const away_team_odds = moneyLineCurrent
                          ? moneyLineCurrent[1]?.odds
                          : scraped_odds?.away_odds;

                        const woPrediction = m?.with_odds.prediction;
                        const nm2 = m?.nm2.prediction;
                        const nm3 = m?.nm3.prediction;
                        const nm4 = m?.nm4.prediction;
                        const nm5 = m?.nm5.prediction;
                        const nmpl = m?.nmpl.prediction;
                        const mlpPrediction = m?.mlp_selection?.prediction;
                        const wooPrediction = m?.without_odds.prediction;

                        const isMmlpSelection =
                          m?.mlp_selection?.prediction?.moneyline?.team &&
                          m?.mlp_selection?.prediction?.score_diff;

                        // mlb
                        const mm1 = m?.mlb?.mm1?.prediction;
                        // const mm2 = m?.mlb?.mm2?.prediction;

                        const isPredicted = type === 'BASEBALL' ? mm1 : nm2;

                        const injury_preds = type === 'BASEBALL' ? mm1 : nmpl;

                        const sharpAction = m?.sharpAction;

                        const bluehome = sharpAction?.sharpAction?.home
                          ? 1
                          : 0 + sharpAction?.bigMoney?.home
                          ? 1
                          : 0 + sharpAction?.proSystem?.home
                          ? 1
                          : 0 + sharpAction?.modelProjection?.home
                          ? 1
                          : 0 + sharpAction?.topExpert?.home
                          ? 1
                          : 0;
                        const blueaway = sharpAction?.sharpAction?.away
                          ? 1
                          : 0 + sharpAction?.bigMoney?.away
                          ? 1
                          : 0 + sharpAction?.proSystem?.away
                          ? 1
                          : 0 + sharpAction?.modelProjection?.away
                          ? 1
                          : 0 + sharpAction?.topExpert?.away
                          ? 1
                          : 0;

                        const val_rev_value = m?.nm2?.value_or_rev_value;

                        const value = val_rev_value?.value;
                        const rev_value = val_rev_value?.rev_value;

                        return (
                          home_team_odds &&
                          away_team_odds &&
                          m.match_id && (
                            <tr className="">
                              <td className="border border-bordergrey px-4 text-start   items-center text-sm text-black py-[10px]">
                                <input
                                  disabled={!isPredicted?.moneyline?.team}
                                  checked={selectedMatches.includes(m)}
                                  onChange={() => {
                                    if (selectedMatches.includes(m)) {
                                      let arr = selectedMatches.filter(
                                        s => s._id !== m._id,
                                      );
                                      setSelectedMatches([...arr]);
                                    } else {
                                      setSelectedMatches([
                                        ...selectedMatches,
                                        m,
                                      ]);
                                    }
                                  }}
                                  className="w-full cursor-pointer"
                                  type="checkbox"
                                />
                              </td>
                              <td className="border border-bordergrey px-4 text-start   items-center text-sm text-black py-[10px]">
                                <p>{formatDateTime(m.date)}</p>
                              </td>
                              <td className="border relative border-bordergrey px-4 text-start text-sm text-black py-[10px]">
                                <div className="flex items-center justify-between">
                                  <div className="grid gap-1 w-[45%]">
                                    <div className="flex font-medium items-center gap-2">
                                      <img
                                        className="w-8"
                                        src={m.match_id?.hometeam?.image}
                                        alt=""
                                      />
                                      <p>
                                        {m.match_id?.hometeam?.abbreviation}
                                      </p>
                                    </div>
                                    <p className="text-center text-sm text-gray-400 font-bold ">
                                      {home_team_odds}
                                    </p>
                                    {injury_preds?.win_proba?.info?.home &&
                                      injury_preds?.win_proba?.info?.away && (
                                        <div>
                                          <p
                                            className={
                                              injury_preds?.win_proba?.info
                                                ?.home >
                                              injury_preds?.win_proba?.info
                                                ?.away
                                                ? 'text-green-500'
                                                : ''
                                            }
                                          >
                                            S -{' '}
                                            {(
                                              Number(
                                                injury_preds?.win_proba?.info
                                                  ?.home,
                                              ) * 100
                                            ).toFixed(2)}
                                            %
                                          </p>
                                          <p
                                            className={
                                              injury_preds?.win_proba?.injury
                                                ?.home >
                                              injury_preds?.win_proba?.injury
                                                ?.away
                                                ? 'text-green-500'
                                                : ''
                                            }
                                          >
                                            I -{' '}
                                            {(
                                              Number(
                                                injury_preds?.win_proba?.injury
                                                  ?.home,
                                              ) * 100
                                            ).toFixed(2)}
                                            %
                                          </p>

                                          {
                                            <>
                                              {sharpAction?.money?.away && (
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.money?.home >
                                                      sharpAction?.money?.away
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CurrencyDollar size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.money?.home,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>
                                              )}
                                              {sharpAction?.bets.away && (
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.bets.away >
                                                      sharpAction?.bets.home
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <Ticket size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.bets.home,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>
                                              )}

                                              {bluehome && (
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    bluehome > blueaway
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CellSignalHigh size={16} /> -{' '}
                                                  {Number(bluehome)}/5
                                                </p>
                                              )}
                                            </>
                                          }
                                        </div>
                                      )}
                                  </div>
                                  <div>
                                    <img
                                      className="w-5 "
                                      src="https://staging.majorleaguepicks.ai/static/media/Group%20392.0c182092185a424278e84d5d9fad4464.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="grid gap-1 w-[45%] justify-end">
                                    <div className="flex font-medium items-center gap-2">
                                      <img
                                        className="w-8"
                                        src={m.match_id?.awayteam?.image}
                                        alt=""
                                      />
                                      <p>
                                        {m.match_id?.awayteam?.abbreviation}
                                      </p>
                                    </div>
                                    <p className="text-center text-sm text-gray-400 font-bold ">
                                      {away_team_odds}
                                    </p>
                                    {injury_preds?.win_proba?.info?.home &&
                                      injury_preds?.win_proba?.info?.away && (
                                        <div>
                                          <p
                                            className={
                                              injury_preds?.win_proba?.info
                                                ?.away >
                                              injury_preds?.win_proba?.info
                                                ?.home
                                                ? 'text-green-500'
                                                : ''
                                            }
                                          >
                                            S -{' '}
                                            {(
                                              Number(
                                                injury_preds?.win_proba?.info
                                                  ?.away,
                                              ) * 100
                                            ).toFixed(2)}
                                            %
                                          </p>
                                          <p
                                            className={
                                              injury_preds?.win_proba?.injury
                                                ?.away >
                                              injury_preds?.win_proba?.injury
                                                ?.home
                                                ? 'text-green-500'
                                                : ''
                                            }
                                          >
                                            I -{' '}
                                            {(
                                              Number(
                                                injury_preds?.win_proba?.injury
                                                  ?.away,
                                              ) * 100
                                            ).toFixed(2)}
                                            %
                                          </p>
                                          {
                                            <>
                                              {sharpAction?.money?.away && (
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.money?.away >
                                                      sharpAction?.money?.home
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CurrencyDollar size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.money?.away,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>
                                              )}

                                              {sharpAction?.bets.home && (
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.bets.home >
                                                      sharpAction?.bets.away
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <Ticket size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.bets.away,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>
                                              )}
                                              {blueaway && (
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    blueaway > bluehome
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CellSignalHigh size={16} /> -{' '}
                                                  {Number(blueaway)}/5
                                                </p>
                                              )}
                                            </>
                                          }
                                        </div>
                                      )}
                                  </div>
                                </div>
                                {!injury_preds?.win_proba?.info?.home &&
                                  !injury_preds?.win_proba?.info?.away && (
                                    <p className="text-xs absolute left-1/2 -translate-x-1/2 bottom-4 opacity-60 w-full text-center">
                                      Model predictions not yet generated
                                    </p>
                                  )}
                              </td>
                              {(type === 'BASKETBALL' &&
                                m?.with_odds.prediction?.moneyline?.team &&
                                m?.with_odds.prediction?.moneyline?.team) ||
                              type === 'BASEBALL' ? (
                                <>
                                  <td className="border border-bordergrey px-4 text-start  items-center text-base text-black py-[10px]">
                                    <WoWooTabel
                                      sport={type}
                                      isMmlpSelection={isMmlpSelection}
                                      withOutOdds={
                                        <>
                                          <img
                                            className="w-8  object-cover"
                                            src={
                                              wooPrediction?.moneyline?.team
                                                ?.image
                                            }
                                            alt={
                                              wooPrediction?.moneyline?.team
                                                ?.abbreviation
                                            }
                                          />
                                          <span>
                                            {
                                              wooPrediction?.moneyline?.team
                                                ?.abbreviation
                                            }{' '}
                                            ({wooPrediction?.moneyline?.odds}) -{' '}
                                            {(
                                              wooPrediction?.moneyline
                                                ?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                      withOdds={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={
                                              woPrediction.moneyline?.team
                                                ?.image
                                            }
                                            alt={
                                              woPrediction.moneyline?.team
                                                ?.abbreviation
                                            }
                                          />
                                          <span>
                                            {
                                              woPrediction.moneyline?.team
                                                ?.abbreviation
                                            }{' '}
                                            ({woPrediction.moneyline?.odds}) -{' '}
                                            {(
                                              woPrediction?.moneyline
                                                ?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                      mlpSelection={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={
                                              mlpPrediction?.moneyline?.team
                                                ?.image
                                            }
                                            alt={
                                              mlpPrediction?.moneyline?.team
                                                ?.abbreviation
                                            }
                                          />
                                          <span>
                                            {
                                              mlpPrediction?.moneyline?.team
                                                ?.abbreviation
                                            }{' '}
                                            ({mlpPrediction?.moneyline?.odds})
                                          </span>
                                        </>
                                      }
                                      nm2={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={nm2.moneyline?.team?.image}
                                            alt={
                                              nm2.moneyline?.team?.abbreviation
                                            }
                                          />
                                          <span>
                                            {nm2.moneyline?.team?.abbreviation}{' '}
                                            ({nm2.moneyline?.odds}) -{' '}
                                            {(
                                              nm2?.moneyline?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                      mm1={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={mm1.moneyline?.team?.image}
                                            alt={
                                              mm1.moneyline?.team?.abbreviation
                                            }
                                          />
                                          <span>
                                            {mm1.moneyline?.team?.abbreviation}{' '}
                                            ({mm1.moneyline?.odds}) -{' '}
                                            {(
                                              mm1?.moneyline?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                      nm3={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={nm3.moneyline?.team?.image}
                                            alt={
                                              nm3.moneyline?.team?.abbreviation
                                            }
                                          />
                                          <span>
                                            {nm3.moneyline?.team?.abbreviation}{' '}
                                            ({nm3.moneyline?.odds}) -{' '}
                                            {(
                                              nm3?.moneyline?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                      nm4={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={nm4.moneyline?.team?.image}
                                            alt={
                                              nm4.moneyline?.team?.abbreviation
                                            }
                                          />
                                          <span>
                                            {nm4.moneyline?.team?.abbreviation}{' '}
                                            ({nm4.moneyline?.odds}) -{' '}
                                            {(
                                              nm4?.moneyline?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                      nm5={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={nm5.moneyline?.team?.image}
                                            alt={
                                              nm5.moneyline?.team?.abbreviation
                                            }
                                          />
                                          <span>
                                            {nm5.moneyline?.team?.abbreviation}{' '}
                                            ({nm5.moneyline?.odds}) -{' '}
                                            {(
                                              nm5?.moneyline?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                      nmpl={
                                        <>
                                          {' '}
                                          <img
                                            className="w-8  object-cover"
                                            src={nmpl.moneyline?.team?.image}
                                            alt={
                                              nmpl.moneyline?.team?.abbreviation
                                            }
                                          />
                                          <span>
                                            {nmpl.moneyline?.team?.abbreviation}{' '}
                                            ({nmpl.moneyline?.odds}) -{' '}
                                            {(
                                              nmpl?.moneyline?.win_porba * 100
                                            ).toFixed(2)}
                                            %
                                          </span>
                                        </>
                                      }
                                    />
                                  </td>
                                  <td className="border text-center border-bordergrey px-4 text-start  items-center text-base text-black py-[10px]">
                                    <WoWooTabel
                                      sport={type}
                                      isMmlpSelection={isMmlpSelection}
                                      withOutOdds={
                                        <p>
                                          {Math.abs(wooPrediction?.score_diff)}
                                        </p>
                                      }
                                      withOdds={
                                        <p>
                                          {Math.abs(woPrediction?.score_diff)}
                                        </p>
                                      }
                                      nm2={Math.abs(nm2?.score_diff)}
                                      mm1={Math.abs(mm1?.score_diff)}
                                      // mm2={Math.abs(mm2?.score_diff)}
                                      nm3={null}
                                      nm4={Math.abs(nm4?.score_diff)}
                                      mlpSelection={
                                        <p>{Math.abs(nm4?.score_diff)}</p>
                                      }
                                      nm5={Math.abs(nm5?.score_diff)}
                                      nmpl={Math.abs(nmpl?.score_diff)}
                                    />
                                  </td>

                                  <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">
                                    <p className="">
                                      {/* {m.with_odds.betType.replace('_', ' ')} */}
                                      <WoWooTabel
                                        sport={type}
                                        isMmlpSelection={isMmlpSelection}
                                        withOutOdds={
                                          <p className="uppercase">
                                            {
                                              m.without_odds?.betType?.split(
                                                '_',
                                              )[0][0]
                                            }
                                            {
                                              m.without_odds?.betType?.split(
                                                '_',
                                              )[1][0]
                                            }
                                          </p>
                                        }
                                        withOdds={
                                          <p className="uppercase">
                                            {
                                              m.with_odds.betType?.split(
                                                '_',
                                              )[0][0]
                                            }
                                            {
                                              m.with_odds.betType?.split(
                                                '_',
                                              )[1][0]
                                            }
                                          </p>
                                        }
                                        nm2={
                                          <p className="uppercase">
                                            {m.nm2?.betType?.split('_')[0][0]}
                                            {m.nm2?.betType?.split('_')[1][0]}
                                          </p>
                                        }
                                        mm1={
                                          <p className="uppercase">
                                            {
                                              m?.mlb?.mm1?.betType?.split(
                                                '_',
                                              )[0][0]
                                            }
                                            {
                                              m?.mlb?.mm1?.betType?.split(
                                                '_',
                                              )[1][0]
                                            }
                                          </p>
                                        }
                                        nm5={
                                          <p className="uppercase">
                                            {m.nm5?.betType?.split('_')[0][0]}
                                            {m.nm5?.betType?.split('_')[1][0]}
                                          </p>
                                        }
                                        nmpl={
                                          <p className="uppercase">
                                            {m.nmpl?.betType?.split('_')[0][0]}
                                            {m.nmpl?.betType?.split('_')[1][0]}
                                          </p>
                                        }
                                        nm3={
                                          <p className="uppercase">
                                            {m.nm3?.betType?.split('_')[0][0]}
                                            {m.nm3?.betType?.split('_')[1][0]}
                                          </p>
                                        }
                                        nm4={
                                          <p className="uppercase">
                                            {m.nm4?.betType?.split('_')[0][0]}
                                            {m.nm4?.betType?.split('_')[1][0]}
                                          </p>
                                        }
                                        mlpSelection={
                                          <p className="uppercase">
                                            {
                                              m?.mlp_selection?.betType?.split(
                                                '_',
                                              )[0][0]
                                            }
                                            {
                                              m?.mlp_selection?.betType?.split(
                                                '_',
                                              )[1][0]
                                            }
                                          </p>
                                        }
                                      />
                                    </p>
                                  </td>
                                </>
                              ) : (
                                <td
                                  className="border border-bordergrey"
                                  colSpan={4}
                                >
                                  <div className="flex justify-center pr-4">
                                    <p className=" text-gray-400">
                                      The prediction for this match has not yet
                                      been made.
                                    </p>
                                  </div>
                                </td>
                              )}
                              {type === 'BASKETBALL' && (
                                <>
                                  <td className="border border-bordergrey px-4 text-start   items-center text-sm text-black py-[10px]">
                                    <div>
                                      {value?.isValue && (
                                        <div className="border-b py-1 pb-2 flex gap-2 items-center">
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={value.team?.image}
                                              alt={value.team?.abbreviation}
                                            />
                                            <span>
                                              {value.team?.abbreviation} (
                                              {value.odds})
                                            </span>
                                          </>
                                        </div>
                                      )}
                                      <p className="border-b py-1">
                                        Value - {value?.isValue ? 'Yes' : 'NO'}
                                      </p>
                                      <p className="border-b py-1">
                                        Value % -{' '}
                                        {value?.isValue
                                          ? `${value?.pct?.toFixed(2)}%`
                                          : 'N/A'}
                                      </p>

                                      <div className="border-b flex gap-2 items-center py-1">
                                        <p>Grade - </p>
                                        {value?.grade && (
                                          <div
                                            className={classNames(
                                              'text-xs p-1 px-2 rounded font-bold text-center',
                                              value?.grade === 1 &&
                                                'bg-green-500',
                                              value?.grade === 3 &&
                                                'bg-purple-500',
                                              value?.grade === 2 &&
                                                'bg-yellow-500',
                                            )}
                                          >
                                            Grade {value?.grade}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <p className="border-b py-1">
                                      VAP(%) - {value?.vap?.toFixed(2)}%
                                    </p>
                                    <p className="">
                                      OIP(%) - {value?.oip?.toFixed(2)}%
                                    </p>
                                  </td>

                                  <td className="border border-bordergrey px-4 text-start   items-center text-sm text-black py-[10px]">
                                    <div>
                                      {rev_value?.isValue && (
                                        <div className="border-b py-1 pb-2 flex gap-2 items-center">
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={rev_value.team?.image}
                                              alt={rev_value.team?.abbreviation}
                                            />
                                            <span>
                                              {rev_value.team?.abbreviation} (
                                              {rev_value.odds})
                                            </span>
                                          </>
                                        </div>
                                      )}
                                      <p className="border-b py-1">
                                        Value -{' '}
                                        {rev_value?.isValue ? 'Yes' : 'NO'}
                                      </p>
                                      <p className="border-b py-1">
                                        Value % -{' '}
                                        {rev_value?.isValue
                                          ? `${rev_value?.pct?.toFixed(2)}%`
                                          : 'N/A'}
                                      </p>

                                      <div className="border-b flex gap-2 items-center py-1">
                                        <p>Grade - </p>
                                        {rev_value?.grade && (
                                          <div
                                            className={classNames(
                                              'text-xs p-1 px-2 rounded font-bold text-center',
                                              rev_value?.grade === 1 &&
                                                'bg-green-500',
                                              rev_value?.grade === 2 &&
                                                'bg-teal-500',
                                              rev_value?.grade === 3 &&
                                                'bg-purple-500',
                                              rev_value?.grade === 4 &&
                                                'bg-yellow-500',
                                            )}
                                          >
                                            Grade {rev_value?.grade}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <p className="border-b py-1">
                                      VAP(%) - {rev_value?.vap?.toFixed(2)}%
                                    </p>
                                    <p className="">
                                      OIP(%) - {rev_value?.oip?.toFixed(2)}%
                                    </p>
                                  </td>
                                </>
                              )}
                              <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">
                                <div className="flex flex-col justify-center gap-4">
                                  <div className="flex justify-center pr-4">
                                    <button
                                      disabled={
                                        m.match_id.status !== 'scheduled' ||
                                        loading ||
                                        loadingPrediction
                                      }
                                      className={classNames(
                                        'rounded-lg bg-bgblue w-full px-5 py-[10px] ml-2 ',
                                        m.match_id.status !== 'scheduled' ||
                                          ((loading || loadingPrediction) &&
                                            'opacity-50'),
                                      )}
                                      onClick={() =>
                                        m.match_id.status === 'scheduled' &&
                                        predictHandler([m.match_id])
                                      }
                                    >
                                      <p className="font-bold text-white text-sm">
                                        {m?.with_odds.prediction?.moneyline
                                          ?.team &&
                                        m?.with_odds.prediction?.moneyline?.team
                                          ? 'Re-run'
                                          : 'Run'}
                                      </p>
                                    </button>
                                  </div>

                                  <div className="flex justify-center pr-4">
                                    <a
                                      target="_blank"
                                      href={`https://staging.majorleaguepicks.ai/nba/${m.match_id.season}/${m.match_id.seasonType}/${m.match_id._id}/${m.match_id.hometeam._id}/${m.match_id.awayteam._id}/offense`}
                                      rel="noreferrer"
                                      className="w-full"
                                    >
                                      <button className="rounded-lg bg-bgblue  px-5 py-[10px] ml-2 w-full cursor-pointer">
                                        <p className="font-bold text-white text-sm">
                                          View
                                        </p>
                                      </button>
                                    </a>
                                  </div>

                                  <div className="flex justify-center pr-4">
                                    <button
                                      onClick={() => {
                                        setShowMitchPredictionPopUp(true);
                                        setAgreeWithTheModel(true);

                                        setMitchPrediction({
                                          match: m.match_id,
                                          model_pred_moneyline:
                                            type === 'BASKETBALL'
                                              ? m?.without_odds.prediction
                                              : m?.mlb?.mm1?.prediction,
                                          predicted_winner: '',
                                          predicted_score_diff: '',
                                        });
                                      }}
                                      className="rounded-lg bg-violet-500 w-full  px-5 py-[10px] ml-2 cursor-pointer"
                                    >
                                      <p className="font-bold text-white text-sm">
                                        Send Email
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </td>

                              {/* <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">

                              </td> */}

                              {/* <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">

                              </td> */}
                            </tr>
                          )
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="md:hidden block">
        <div className="w-full mb-10 ">
          <div className="filter  flex  w-full overflow-x-auto md:gap-x-8">
            <div className="flex flex-col w-full bg-white w-full rounded-b-lg  pt-7 px-3 pb-9">
              <div className=" text-sm ">
                <div className=" pb-3">
                  <div className="flex">
                    <p className="font-medium">
                      Date:{' '}
                      {tobematches &&
                        tobematches.length &&
                        formatDate(tobematches[0].date)}
                    </p>
                  </div>
                </div>

                {/* <div className="flex items-center gap-2 justify-center">
                <div className="flex gap-4">
                  <p>w/o : With Odds</p>
                  <div className="h-6 w-0.5 bg-black"></div>
                  <p>wo/o : Without Odds</p>
                  <div className="h-6 w-0.5 bg-black"></div>
                  <p>m/s : MLP Selection</p>
                </div>
              </div> */}

                <div className="flex gap-2">
                  <Link to={'/create-parlay'}>
                    <button
                      disabled={loading || loadingPrediction}
                      className={classNames(
                        'rounded-lg border-bgblue border-2 px-4 py-[10px] ml-2 cursor-pointer',
                        (loading || loadingPrediction) && 'opacity-50',
                      )}
                    >
                      <p className="font-bold flex items-center gap-2 text-sm">
                        Parlay <Plus weight="bold" />
                      </p>
                    </button>
                  </Link>

                  <button
                    disabled={loading || loadingPrediction}
                    className={classNames(
                      'rounded-lg bg-bgblue px-4  ml-2 cursor-pointer',
                      (loading || loadingPrediction) && 'opacity-50',
                    )}
                    onClick={() => predictHandler(match_ids)}
                  >
                    <p className="font-bold flex gap-2 items-center text-white text-sm">
                      <Gear weight="bold" />
                      Run
                    </p>
                  </button>

                  <button
                    disabled={
                      loading || loadingPrediction || !selectedMatches.length
                    }
                    className={classNames(
                      'rounded-lg bg-violet-500 px-4 py-[10px] ml-2 cursor-pointer',
                      (loading ||
                        loadingPrediction ||
                        !selectedMatches.length) &&
                        'opacity-50',
                    )}
                    onClick={() => setShowSendAllPredictionsMitch(true)}
                  >
                    <p className="font-bold flex gap-2 items-center text-white text-sm">
                      Sel. <Envelope weight="bold" />
                    </p>
                  </button>
                </div>
              </div>

              {loading ? (
                <Loading />
              ) : (
                <div className="mt-5 grid gap-3">
                  {tobematches
                    ?.filter(s => s.sport === type)
                    ?.map((m, index) => {
                      const moneyLineCurrent = m?.odds?.lines?.filter(
                        s => s.name === 'moneyline_current',
                      )[0]?.outcomes;

                      const scraped_odds = m?.scraped_odds;

                      const home_team_odds = moneyLineCurrent
                        ? moneyLineCurrent[0]?.odds
                        : scraped_odds?.home_odds;
                      const away_team_odds = moneyLineCurrent
                        ? moneyLineCurrent[1]?.odds
                        : scraped_odds?.away_odds;

                      const woPrediction = m?.with_odds.prediction;
                      const nm2 = m?.nm2.prediction;
                      const nm3 = m?.nm3.prediction;
                      const nm4 = m?.nm4.prediction;
                      const nm5 = m?.nm5.prediction;
                      const nmpl = m?.nmpl.prediction;
                      const mlpPrediction = m?.mlp_selection?.prediction;
                      const wooPrediction = m?.without_odds.prediction;

                      const isMmlpSelection =
                        m?.mlp_selection?.prediction?.moneyline?.team &&
                        m?.mlp_selection?.prediction?.score_diff;

                      // mlb
                      const mm1 = m?.mlb?.mm1?.prediction;
                      // const mm2 = m?.mlb?.mm2?.prediction;
                      const isPredicted = type === 'BASEBALL' ? mm1 : nm2;

                      const injury_preds = type === 'BASEBALL' ? mm1 : nmpl;

                      const sharpAction = m?.sharpAction;

                      const bluehome = sharpAction?.sharpAction?.home
                        ? 1
                        : 0 + sharpAction?.bigMoney?.home
                        ? 1
                        : 0 + sharpAction?.proSystem?.home
                        ? 1
                        : 0 + sharpAction?.modelProjection?.home
                        ? 1
                        : 0 + sharpAction?.topExpert?.home
                        ? 1
                        : 0;
                      const blueaway = sharpAction?.sharpAction?.away
                        ? 1
                        : 0 + sharpAction?.bigMoney?.away
                        ? 1
                        : 0 + sharpAction?.proSystem?.away
                        ? 1
                        : 0 + sharpAction?.modelProjection?.away
                        ? 1
                        : 0 + sharpAction?.topExpert?.away
                        ? 1
                        : 0;

                      const val_rev_value = m?.nm2?.value_or_rev_value;

                      const value = val_rev_value?.value;
                      const rev_value = val_rev_value?.rev_value;

                      return (
                        home_team_odds &&
                        away_team_odds && (
                          <div>
                            <div className="border rounded-md">
                              <div
                                onClick={() => {
                                  const sels = [...showDropdowns];
                                  if (sels.includes(m._id)) {
                                    let newSels = sels.filter(s => s !== m._id);
                                    setShowDropdowns([...newSels]);
                                  } else {
                                    sels.push(m._id);
                                    setShowDropdowns([...sels]);
                                  }
                                }}
                                className="border border-bordergrey px-4 text-sm text-black py-[10px] grid items-center grid-cols-2"
                              >
                                <div className="flex gap-1">
                                  <input
                                    disabled={!isPredicted?.moneyline?.team}
                                    checked={selectedMatches.includes(m)}
                                    onClick={e => {
                                      e.stopPropagation();
                                    }}
                                    onChange={e => {
                                      if (selectedMatches.includes(m)) {
                                        let arr = selectedMatches.filter(
                                          s => s._id !== m._id,
                                        );
                                        setSelectedMatches([...arr]);
                                      } else {
                                        setSelectedMatches([
                                          ...selectedMatches,
                                          m,
                                        ]);
                                      }
                                    }}
                                    className="cursor-pointer"
                                    type="checkbox"
                                  />
                                  <p>{formatDateTime(m.date)}</p>
                                </div>
                                <div className="flex justify-between gap-5 items-center">
                                  <div className="grid gap-1 w-[45%]">
                                    <div className="flex font-medium items-center gap-2">
                                      <img
                                        className="w-8"
                                        src={m.match_id?.hometeam?.image}
                                        alt=""
                                      />
                                      {/* <p>{m.match_ixd?.hometeam?.abbreviation}</p> */}
                                    </div>
                                    <p className="text-center text-sm text-gray-400 font-bold ">
                                      {home_team_odds}
                                    </p>
                                  </div>
                                  <img
                                    className="w-5 "
                                    src="https://staging.majorleaguepicks.ai/static/media/Group%20392.0c182092185a424278e84d5d9fad4464.svg"
                                    alt=""
                                  />
                                  <div className="grid gap-1 w-[45%]">
                                    <div className="flex font-medium items-center gap-2">
                                      <img
                                        className="w-8"
                                        src={m.match_id?.awayteam?.image}
                                        alt=""
                                      />
                                      {/* <p>{m.match_ixd?.awayteam?.abbreviation}</p> */}
                                    </div>
                                    <p className="text-center text-sm text-gray-400 font-bold ">
                                      {away_team_odds}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {showDropdowns.includes(m._id) && (
                                <div>
                                  <div className="grid grid-cols-3 ">
                                    <div className="col-span-2 text-[13px] border-r">
                                      <div className="flex items-center p-3 justify-between border-b">
                                        <div className="grid gap-1 w-[45%]">
                                          <div className="flex font-medium items-center gap-2">
                                            <img
                                              className="w-8"
                                              src={m.match_id?.hometeam?.image}
                                              alt=""
                                            />
                                            {/* <p>
                                        {m.match_id?.hometeam?.abbreviation}
                                      </p> */}
                                          </div>
                                          {/* <p className="text-center text-sm text-gray-400 font-bold ">
                                      {moneyLineCurrent[0]?.odds}
                                    </p> */}
                                          <div>
                                            <p
                                              className={
                                                injury_preds?.win_proba?.info
                                                  ?.home >
                                                injury_preds?.win_proba?.info
                                                  ?.away
                                                  ? 'text-green-500'
                                                  : ''
                                              }
                                            >
                                              S -{' '}
                                              {(
                                                Number(
                                                  injury_preds?.win_proba?.info
                                                    ?.home,
                                                ) * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                            <p
                                              className={
                                                injury_preds?.win_proba?.injury
                                                  ?.home >
                                                injury_preds?.win_proba?.injury
                                                  ?.away
                                                  ? 'text-green-500'
                                                  : ''
                                              }
                                            >
                                              I -{' '}
                                              {(
                                                Number(
                                                  injury_preds?.win_proba
                                                    ?.injury?.home,
                                                ) * 100
                                              ).toFixed(2)}
                                              %
                                            </p>

                                            {
                                              <>
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.money?.home >
                                                      sharpAction?.money?.away
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CurrencyDollar size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.money?.home,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.bets.away >
                                                      sharpAction?.bets.home
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <Ticket size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.bets.home,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>

                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    bluehome > blueaway
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CellSignalHigh size={16} /> -{' '}
                                                  {Number(bluehome)}/5
                                                </p>
                                              </>
                                            }
                                          </div>
                                        </div>
                                        <div>
                                          <img
                                            className="w-5 "
                                            src="https://staging.majorleaguepicks.ai/static/media/Group%20392.0c182092185a424278e84d5d9fad4464.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div className="grid gap-1 w-[45%] justify-end">
                                          <div className="flex font-medium items-center gap-2">
                                            <img
                                              className="w-8"
                                              src={m.match_id?.awayteam?.image}
                                              alt=""
                                            />
                                            {/* <p>
                                        {m.match_id?.awayteam?.abbreviation}
                                      </p> */}
                                          </div>
                                          {/* <p className="text-center text-sm text-gray-400 font-bold ">
                                      {moneyLineCurrent[1]?.odds}
                                    </p> */}
                                          <div>
                                            <p
                                              className={
                                                injury_preds?.win_proba?.info
                                                  ?.away >
                                                injury_preds?.win_proba?.info
                                                  ?.home
                                                  ? 'text-green-500'
                                                  : ''
                                              }
                                            >
                                              S -{' '}
                                              {(
                                                Number(
                                                  injury_preds?.win_proba?.info
                                                    ?.away,
                                                ) * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                            <p
                                              className={
                                                injury_preds?.win_proba?.injury
                                                  ?.away >
                                                injury_preds?.win_proba?.injury
                                                  ?.home
                                                  ? 'text-green-500'
                                                  : ''
                                              }
                                            >
                                              I -{' '}
                                              {(
                                                Number(
                                                  injury_preds?.win_proba
                                                    ?.injury?.away,
                                                ) * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                            {
                                              <>
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.money?.away >
                                                      sharpAction?.money?.home
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CurrencyDollar size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.money?.away,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>

                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    sharpAction?.bets.home >
                                                      sharpAction?.bets.away
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <Ticket size={16} /> -{' '}
                                                  {(
                                                    Number(
                                                      sharpAction?.bets.away,
                                                    ) * 100
                                                  ).toFixed(2)}
                                                  %
                                                </p>
                                                <p
                                                  className={classNames(
                                                    'flex  items-center',
                                                    blueaway > bluehome
                                                      ? 'text-green-500'
                                                      : '',
                                                  )}
                                                >
                                                  <CellSignalHigh size={16} /> -{' '}
                                                  {Number(blueaway)}/5
                                                </p>
                                              </>
                                            }
                                          </div>
                                        </div>
                                      </div>

                                      {type === 'BASKETBALL' && (
                                        <>
                                          <div className="p-3 border-b">
                                            <p className="font-bold">Value</p>
                                            {value?.isValue && (
                                              <div className="border-b py-1 pb-2 flex gap-2 items-center">
                                                <>
                                                  {' '}
                                                  <img
                                                    className="w-8  object-cover"
                                                    src={value.team?.image}
                                                    alt={
                                                      value.team?.abbreviation
                                                    }
                                                  />
                                                  <span>
                                                    {value.team?.abbreviation} (
                                                    {value.odds})
                                                  </span>
                                                </>
                                              </div>
                                            )}
                                            <div className="grid grid-cols-2 text-xs">
                                              <p className="border-b py-1">
                                                Val -{' '}
                                                {value?.isValue ? 'Yes' : 'NO'}
                                              </p>
                                              <p className="border-b py-1">
                                                Val % -{' '}
                                                {value?.isValue
                                                  ? `${value?.pct?.toFixed(2)}%`
                                                  : 'N/A'}
                                              </p>

                                              <div className="border-b flex gap-2 items-center py-1">
                                                <p>Gr - </p>
                                                {value?.grade && (
                                                  <div
                                                    className={classNames(
                                                      'text-xs p-1 px-2 rounded font-bold text-center',
                                                      value?.grade === 1 &&
                                                        'bg-green-500',
                                                      value?.grade === 3 &&
                                                        'bg-purple-500',
                                                      value?.grade === 2 &&
                                                        'bg-yellow-500',
                                                    )}
                                                  >
                                                    Grade {value?.grade}
                                                  </div>
                                                )}
                                              </div>

                                              <p className="border-b py-1">
                                                VAP(%) -{' '}
                                                {value?.vap?.toFixed(2)}%
                                              </p>
                                              <p className="py-1">
                                                OIP(%) -{' '}
                                                {value?.oip?.toFixed(2)}%
                                              </p>
                                            </div>
                                          </div>

                                          <div className="p-3">
                                            <p className="font-bold">
                                              {' '}
                                              Rev Value
                                            </p>
                                            {rev_value?.isValue && (
                                              <div className="border-b py-1 pb-2 flex gap-2 items-center">
                                                <>
                                                  {' '}
                                                  <img
                                                    className="w-8  object-cover"
                                                    src={rev_value.team?.image}
                                                    alt={
                                                      rev_value.team
                                                        ?.abbreviation
                                                    }
                                                  />
                                                  <span>
                                                    {
                                                      rev_value.team
                                                        ?.abbreviation
                                                    }{' '}
                                                    ({rev_value.odds})
                                                  </span>
                                                </>
                                              </div>
                                            )}
                                            <div className="grid grid-cols-2 text-xs">
                                              <p className="border-b py-1">
                                                Val -{' '}
                                                {rev_value?.isValue
                                                  ? 'Yes'
                                                  : 'NO'}
                                              </p>
                                              <p className="border-b py-1">
                                                Val % -{' '}
                                                {rev_value?.isValue
                                                  ? `${rev_value?.pct?.toFixed(
                                                      2,
                                                    )}%`
                                                  : 'N/A'}
                                              </p>

                                              <div className="border-b flex gap-2 items-center py-1">
                                                <p>Gr - </p>
                                                {rev_value?.grade && (
                                                  <div
                                                    className={classNames(
                                                      'text-xs p-1 px-2 rounded font-bold text-center',
                                                      rev_value?.grade === 1 &&
                                                        'bg-green-500',
                                                      rev_value?.grade === 2 &&
                                                        'bg-teal-500',
                                                      rev_value?.grade === 3 &&
                                                        'bg-purple-500',
                                                      rev_value?.grade === 4 &&
                                                        'bg-yellow-500',
                                                    )}
                                                  >
                                                    Gr {rev_value?.grade}
                                                  </div>
                                                )}
                                              </div>

                                              <p className="border-b py-1">
                                                VAP(%) -{' '}
                                                {rev_value?.vap?.toFixed(2)}%
                                              </p>
                                              <p className=" py-1">
                                                OIP(%) -{' '}
                                                {rev_value?.oip?.toFixed(2)}%
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="text-xs ">
                                      <WoWooTabel
                                        sport={type}
                                        isMmlpSelection={isMmlpSelection}
                                        withOutOdds={
                                          <>
                                            <img
                                              className="w-8  object-cover"
                                              src={
                                                wooPrediction?.moneyline?.team
                                                  ?.image
                                              }
                                              alt={
                                                wooPrediction?.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p>
                                              ({wooPrediction?.moneyline?.odds})
                                            </p>
                                            <p>
                                              {(
                                                wooPrediction?.moneyline
                                                  ?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                        withOdds={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={
                                                woPrediction.moneyline?.team
                                                  ?.image
                                              }
                                              alt={
                                                woPrediction.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p>
                                              ({woPrediction.moneyline?.odds})
                                            </p>
                                            <p>
                                              {(
                                                woPrediction?.moneyline
                                                  ?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                        mlpSelection={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={
                                                mlpPrediction?.moneyline?.team
                                                  ?.image
                                              }
                                              alt={
                                                mlpPrediction?.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <span>
                                              ({mlpPrediction?.moneyline?.odds})
                                            </span>
                                          </>
                                        }
                                        nm2={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={nm2.moneyline?.team?.image}
                                              alt={
                                                nm2.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p> ({nm2.moneyline?.odds})</p>
                                            <p>
                                              {(
                                                nm2?.moneyline?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                        mm1={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={mm1.moneyline?.team?.image}
                                              alt={
                                                mm1.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p>({mm1.moneyline?.odds})</p>
                                            <p>
                                              {(
                                                mm1?.moneyline?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                        nm3={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={nm3.moneyline?.team?.image}
                                              alt={
                                                nm3.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p>({nm3.moneyline?.odds})</p>
                                            <p>
                                              {(
                                                nm3?.moneyline?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                        nm4={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={nm4.moneyline?.team?.image}
                                              alt={
                                                nm4.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p>({nm4.moneyline?.odds})</p>
                                            <p>
                                              {(
                                                nm4?.moneyline?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                        nm5={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={nm5.moneyline?.team?.image}
                                              alt={
                                                nm5.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p>({nm5.moneyline?.odds})</p>
                                            <p>
                                              {(
                                                nm5?.moneyline?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                        nmpl={
                                          <>
                                            {' '}
                                            <img
                                              className="w-8  object-cover"
                                              src={nmpl.moneyline?.team?.image}
                                              alt={
                                                nmpl.moneyline?.team
                                                  ?.abbreviation
                                              }
                                            />
                                            <p>({nmpl.moneyline?.odds})</p>
                                            <p>
                                              {(
                                                nmpl?.moneyline?.win_porba * 100
                                              ).toFixed(2)}
                                              %
                                            </p>
                                          </>
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="p-3 border-t">
                                    <div className="flex justify-center gap-2">
                                      <div className="flex justify-center ">
                                        <button
                                          disabled={
                                            m.match_id.status !== 'scheduled' ||
                                            loading ||
                                            loadingPrediction
                                          }
                                          className={classNames(
                                            'rounded-lg bg-bgblue w-full px-5 py-[10px] ml-2 ',
                                            m.match_id.status !== 'scheduled' ||
                                              ((loading || loadingPrediction) &&
                                                'opacity-50'),
                                          )}
                                          onClick={() =>
                                            m.match_id.status === 'scheduled' &&
                                            predictHandler([m.match_id])
                                          }
                                        >
                                          <p className="font-bold text-white flex items-center gap-2 text-sm">
                                            {m?.with_odds.prediction?.moneyline
                                              ?.team &&
                                            m?.with_odds.prediction?.moneyline
                                              ?.team ? (
                                              <>
                                                <ArrowsCounterClockwise weight="bold" />
                                                <p>Run</p>
                                              </>
                                            ) : (
                                              <>
                                                <Gear weight="bold" />
                                                <p>Run</p>
                                              </>
                                            )}
                                          </p>
                                        </button>
                                      </div>

                                      <div className="flex justify-center ">
                                        <a
                                          target="_blank"
                                          href={`https://staging.majorleaguepicks.ai/nba/${m.match_id.season}/${m.match_id.seasonType}/${m.match_id._id}/${m.match_id.hometeam._id}/${m.match_id.awayteam._id}/offense`}
                                          rel="noreferrer"
                                          className="w-full"
                                        >
                                          <button className="rounded-lg bg-bgblue  px-5 py-[10px] ml-2 w-full cursor-pointer">
                                            <p className="font-bold text-white text-sm">
                                              View
                                            </p>
                                          </button>
                                        </a>
                                      </div>

                                      <div className="flex justify-center ">
                                        <button
                                          onClick={() => {
                                            setShowMitchPredictionPopUp(true);
                                            setAgreeWithTheModel(true);

                                            setMitchPrediction({
                                              match: m.match_id,
                                              model_pred_moneyline:
                                                type === 'BASKETBALL'
                                                  ? m?.without_odds.prediction
                                                  : m?.mlb?.mm1?.prediction,
                                              predicted_winner: '',
                                              predicted_score_diff: '',
                                            });
                                          }}
                                          className="rounded-lg bg-violet-500 w-full  px-5 py-[10px] ml-2 cursor-pointer"
                                        >
                                          <p className="font-bold text-white flex gap-2 items-center text-sm">
                                            Send <EnvelopeOpen weight="bold" />
                                          </p>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showMitchPredictionPopUp && (
        <ShowMitchPredictionPopUp
          setShowMitchPredictionPopUp={setShowMitchPredictionPopUp}
          mitchPrediction={mitchPrediction}
          setMitchPrediction={setMitchPrediction}
          agreeWithTheModel={agreeWithTheModel}
          sendEmailHandler={sendEmailHandler}
          setAgreeWithTheModel={setAgreeWithTheModel}
        />
      )}

      {showSendAllPredictionsMitch && (
        <div>
          <div className="fixed top-0 left-0 bg-black z-[999999] overflow-x-auto  bg-opacity-40 w-screen h-screen">
            <div
              onClick={e => e.stopPropagation()}
              className="absolute top-1/2 md:left-1/2 md:-translate-x-1/2  left-0 mx-4 rounded-md -translate-y-1/2 bg-white px-6 py-4 h-[80%] w-[92%] md:w-auto"
            >
              <div className="md:w-[1000px] w-full h-[80%] ">
                <div className="flex justify-between pb-1 items-center">
                  <p className="text-lg font-medium">Add MLP Selection</p>
                  <X
                    onClick={() => setShowSendAllPredictionsMitch(false)}
                    className="cursor-pointer"
                    size={24}
                  />
                </div>

                <div className="grid gap-4 mt-2 border-t border-gray-400 h-full overflow-y-auto">
                  {selectedMatches.map(s => {
                    let match = multipleMLPSelections?.filter(
                      m => m.match_id === s.match_id._id,
                    )[0];

                    return (
                      <div className="grid md:grid-cols-4 gap-1 p-3 border  border-gray-400 ">
                        {/* Match */}
                        <div className="p-2">
                          <div className="flex items-center justify-between ">
                            <div className="grid gap-1 w-[45%]">
                              <div className="flex font-medium items-center gap-2">
                                <img
                                  className="w-8"
                                  src={s.match_id?.hometeam?.image}
                                  alt=""
                                />
                                <p>{s.match_id?.hometeam?.abbreviation}</p>
                              </div>
                            </div>
                            <div>
                              <img
                                className="w-5 "
                                src="https://staging.majorleaguepicks.ai/static/media/Group%20392.0c182092185a424278e84d5d9fad4464.svg"
                                alt=""
                              />
                            </div>
                            <div className="grid gap-1 w-[45%] justify-end">
                              <div className="flex font-medium items-center gap-2">
                                <img
                                  className="w-8"
                                  src={s.match_id?.awayteam?.image}
                                  alt=""
                                />
                                <p>{s.match_id?.awayteam?.abbreviation}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Winner */}

                        <div className=" p-2">
                          <select
                            name=""
                            className="w-full h-full border py-2"
                            value={match.winner}
                            onChange={e => {
                              const arr = [...multipleMLPSelections];
                              const findIndex = arr.findIndex(
                                s => s.match_id === match.match_id,
                              );
                              arr[findIndex].winner = e.target.value;
                              setMultipleMLPSelections([...arr]);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value={s.match_id?.hometeam?.name}>
                              {s.match_id?.hometeam?.name}
                            </option>
                            <option value={s.match_id?.awayteam?.name}>
                              {s.match_id?.awayteam?.name}
                            </option>
                          </select>
                        </div>

                        <div className="p-2">
                          <input
                            placeholder="score difference"
                            type="number"
                            className="w-full h-full border p-2 "
                            value={match.score_diff}
                            onChange={e => {
                              const arr = [...multipleMLPSelections];
                              const findIndex = arr.findIndex(
                                s => s.match_id === match.match_id,
                              );
                              arr[findIndex].score_diff = Number(
                                e.target.value,
                              );
                              setMultipleMLPSelections([...arr]);
                            }}
                          />
                        </div>

                        <textarea
                          placeholder="Comment..."
                          className="border border-gray-400 py-2 px-4"
                          value={match.comment}
                          onChange={e => {
                            const arr = [...multipleMLPSelections];
                            const findIndex = arr.findIndex(
                              s => s.match_id === match.match_id,
                            );
                            arr[findIndex].comment = e.target.value;
                            setMultipleMLPSelections([...arr]);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>

                <div className="pt-4 grid grid-cols-2 gap-2">
                  <button
                    onClick={() => setShowSendAllPredictionsMitch(false)}
                    className="text-md py-3 border border-gray-400"
                  >
                    {'Cancel'}
                  </button>
                  <button
                    onClick={() => sendMultipleSelectionsEmailHandler()}
                    className={classNames(
                      'text-md py-3 text-white border bg-blue-500',
                    )}
                  >
                    {' Send Email'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePrediction;
