export enum betType {
  high_return = 'high_return',
  medium_return = 'medium_return',
  low_return = 'low_return',
}

export interface IPrediction {
  _id: string;
  date: string;
  match_id: any;
  odds: any;
  profits: any;
  sport: string;
  result: {
    winner: any;
    score_diff: number;
    spread_winner: string;
  };
  with_odds: {
    betType: betType;
    prediction: {
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
  };
  without_odds: {
    betType: betType;
    prediction: {
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
  };
  mlp_selection: {
    betType: betType;
    comment: string;
    bet_proof: string;
    prediction: {
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
  };
  nm2: {
    betType: betType;
    prediction: {
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
    value_or_rev_value: {
      value: {
        team: string;
        odds: number;
        pct: number;
        isValue: boolean;
        grade: 1 | 2 | 3 | 4 | null;
        vap: number;
        oip: number;
      };
      rev_value: {
        team: string;
        odds: number;
        pct: number;
        isValue: boolean;
        grade: 1 | 2 | 3 | 4 | null;
        vap: number;
        oip: number;
      };
    };
  };
  nm2V: {
    betType: betType;
    prediction: {
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
    value_or_rev_value: {
      value: {
        team: string;
        odds: number;
        pct: number;
        isValue: boolean;
        grade: 1 | 2 | 3 | 4 | null;
        vap: number;
        oip: number;
      };
      rev_value: {
        team: string;
        odds: number;
        pct: number;
        isValue: boolean;
        grade: 1 | 2 | 3 | 4 | null;
        vap: number;
        oip: number;
      };
    };
  };
  nm3: {
    betType: betType;
    prediction: {
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
  };
  nm4: {
    betType: betType;
    prediction: {
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
  };
  nm5: {
    betType: betType;
    prediction: {
      win_proba: {
        info: {
          home: { type: Number };
          away: { type: Number };
        };
        injury: {
          home: { type: Number };
          away: { type: Number };
        };
      };
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
  };
  nmpl: {
    betType: betType;
    prediction: {
      win_proba: {
        info: {
          home: { type: Number };
          away: { type: Number };
        };
        injury: {
          home: { type: Number };
          away: { type: Number };
        };
      };
      score_diff: number;
      moneyline: {
        team: any;
        odds: number;
        win_porba: number;
      };
      spread: {
        team: any;
        odds: number;
        spread: number;
      };
    };
    isWon: number | null;
    is_spread_Won: number | null;
  };
  mlb: {
    mm1: {
      betType: betType;
      prediction: {
        win_proba: {
          info: {
            home: { type: Number };
            away: { type: Number };
          };
          injury: {
            home: { type: Number };
            away: { type: Number };
          };
        };
        score_diff: number;
        moneyline: {
          team: any;
          odds: number;
          win_porba: number;
        };
        spread: {
          team: any;
          odds: number;
          spread: number;
        };
      };
      isWon: number | null;
      is_spread_Won: number | null;
    };
  };
}
