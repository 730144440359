import classNames from 'classnames';
import React from 'react';
import Pages from './Pages';
import './style/custom.pagination.css';
interface State {
  currentPage?: string | number;
  limit?: number | string;
  totalCount?: number | string;
  setPage?: any;
  onLimitChnage?: any;
}
const Pagination = ({
  currentPage,
  limit,
  totalCount,
  setPage,
  onLimitChnage,
}: State) => {
  return (
    <>
      <div className="flex justify-between ietms-center text-sm">
        <div className="flex gap-2 items-center">
          <h5 className="text-[#1C1D1C]">Showing</h5>
          <select
            value={limit}
            onChange={onLimitChnage}
            className="  py-2 px-4 border w-[5rem] customArrow bg-[right_1rem_center] appearance-none capitalize text-less-dark focus:outline-none"
            name=""
            id=""
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <p className="text-gray-text px-4">items per page</p>
        </div>

        <Pages
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={limit}
          onPageChange={pg => setPage(pg)}
        />
      </div>
    </>
  );
};

export default Pagination;
