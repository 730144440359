import { AnyAction } from 'redux';

import {
  GET_GPT_PREDICTION_FAILURE,
  GET_GPT_PREDICTION_REQUEST,
  GET_GPT_PREDICTION_SUCCESS,
  GET_HTH_PASTMATCHES_FAILURE,
  GET_HTH_PASTMATCHES_REQUEST,
  GET_HTH_PASTMATCHES_SUCCESS,
  GET_INJURIES_FAILURE,
  GET_INJURIES_REQUEST,
  GET_INJURIES_SUCCESS,
  GET_PAST_MATCHES_FAILURE,
  GET_PAST_MATCHES_REQUEST,
  GET_PAST_MATCHES_SUCCESS,
  GET_PLAYER_STATS_FAILURE,
  GET_PLAYER_STATS_REQUEST,
  GET_PLAYER_STATS_SUCCESS,
  GET_ROSTER_FAILURE,
  GET_ROSTER_REQUEST,
  GET_ROSTER_SUCCESS,
  GET_STARTING_PITCHERS_FAILURE,
  GET_STARTING_PITCHERS_REQUEST,
  GET_STARTING_PITCHERS_SUCCESS,
} from '../constants/SidebarConstants';
export const getstartingPitchersReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_STARTING_PITCHERS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_STARTING_PITCHERS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_STARTING_PITCHERS_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getInjuriesReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_INJURIES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_INJURIES_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_INJURIES_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getRosterReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_ROSTER_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ROSTER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_ROSTER_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getPastMatchesWithTeamIdReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_PAST_MATCHES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PAST_MATCHES_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_PAST_MATCHES_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getHTHpastMatchesReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_HTH_PASTMATCHES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_HTH_PASTMATCHES_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_HTH_PASTMATCHES_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getGPTpredictionReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_GPT_PREDICTION_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_GPT_PREDICTION_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_GPT_PREDICTION_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const getPlayerStatsReducer = (
  state = {
    data: null,
    error: null,
    loading: true,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_PLAYER_STATS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PLAYER_STATS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_PLAYER_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};
