import { AnyAction } from "@reduxjs/toolkit";

import { GET_REGISTERED_USER_LIST_REQUEST,GET_REGISTERED_USER_LIST_SUCCESS,
  GET_REGISTERED_USER_LIST_FAILURE,REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE } from "../constants/RegisterUsersConstants";

export const getRegisteredUserReducer = (state = {
  data: null,
  error: null,
  loading: false,
  query: null,
},
action: AnyAction,)=>{

  switch (action.type) {
    case GET_REGISTERED_USER_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_REGISTERED_USER_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null,   query: action.query};
    case GET_REGISTERED_USER_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload, data: null };
    
    default:
      return state;
      
  }

}

export const registerUserReducer = (state = {
  data:null,
  error:null,
  loading:false
},action: AnyAction)=>{

  switch (action.type) {
    case REGISTER_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };;
    case REGISTER_USER_FAILURE :
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }

}