import { Helmet } from 'react-helmet-async';
import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Dashboard from '../../admincomponents/Dashboard';
import { TopNavAdmin } from '../../admincomponents/common/TopNav';
import Login from '../../admincomponents/Login';

export function AdminLoginPage() {
  const location = useLocation();
  const pathSegment = location.pathname.split('/');
  const requiredPathSegment = pathSegment[pathSegment.length - 1];

  return (
    <>
      <Helmet>
        <title>AdminLogin Page</title>
        <meta
          name="description"
          content="A React Boilerplate application homepage"
        />
      </Helmet>
      <div className="">
        <Login />
      </div>
    </>
  );
}
