import React, { useEffect, useState } from 'react';
import MainTab from './comps/MainTab';
import Tab from './comps/Tab';
import TrackerPart from '.';

const Tracker = () => {
  const [mainTab, setMainTab] = useState<{
    nm1?: boolean;
    nm2?: boolean;
    nm3?: boolean;
    nm4?: boolean;
    nm5?: boolean;
    nmpl?: boolean;
    nm2v?: boolean;
    mm1?: boolean;
    mm1_sc?: boolean;
    mlps?: boolean;
  }>({
    nm1: false,
    nm2: false,
    nm3: false,
    nm4: false,
    nm5: false,
    nm2v: false,
    nmpl: false,
    mm1: false,
    mm1_sc: false,
    mlps: true,
  });
  const type = mainTab.nm1
    ? 'nm1'
    : mainTab.nm2
    ? 'nm2'
    : mainTab.nm3
    ? 'nm3'
    : mainTab.nm4
    ? 'nm4'
    : mainTab.mlps
    ? 'mlp_selection'
    : mainTab.nm5
    ? 'nm5'
    : mainTab.nmpl
    ? 'nmpl'
    : mainTab.mm1
    ? 'mlb.mm1'
    : mainTab.nm2v
    ? 'nm2V'
    : mainTab.mm1_sc
    ? 'mlb.mm1'
    : '';

  const loggedInUser = JSON.parse(localStorage.getItem('userInfo')!);

  const userRole = loggedInUser?.role;
  const userSubRole = loggedInUser?.subRole;

  const isInvestor = userRole === 'ADMIN' && userSubRole === 'INVESTOR';

  // useEffect(() => {
  //   if (isInvestor) {
  //     setMainTab({ mlps: true });
  //   }
  // }, [isInvestor]);
  return (
    <>
      {isInvestor && (
        <div className="text-center px-10 pt-7">
          We feed AI & ML Models important data points that are used to make a
          prediction on the outcomes of games. Though we don’t fully rely on it,
          every prediction is overseen by a human (SportsPulse Analysts) that
          has their finger on the pulse of sports.
          <br />
          If an SportsPulse Analysts disagrees with the prediction that the
          Predictive Model made they will overturn the prediction and offer
          insights for corrections to the model in the future. This means that
          the model is always learning and improving its accuracy.
          <br />
          SportsPulse selections are the final selections that are used to wager
          1% mark of the bankroll.
        </div>
      )}
      <div className="flex px-8  mt-4">
        {!isInvestor ? (
          <div className="flex border-[#F5F5F5]  px-8 text-sm  ">
            <Tab
              text={'NM1'}
              onClick={() => setMainTab({ nm1: true })}
              conditon={mainTab.nm1}
            />
            <Tab
              text={'NM2'}
              onClick={() => setMainTab({ nm2: true })}
              conditon={mainTab.nm2}
            />
            <Tab
              text={'NM3'}
              onClick={() => setMainTab({ nm3: true })}
              conditon={mainTab.nm3}
            />
            <Tab
              text={'NM4'}
              onClick={() => setMainTab({ nm4: true })}
              conditon={mainTab.nm4}
            />
            <Tab
              text={'NM5'}
              onClick={() => setMainTab({ nm5: true })}
              conditon={mainTab.nm5}
            />

            <Tab
              text={'NMPL'}
              onClick={() => setMainTab({ nmpl: true })}
              conditon={mainTab.nmpl}
            />

            <Tab
              text={'NM2V'}
              onClick={() => setMainTab({ nm2v: true })}
              conditon={mainTab.nm2v}
            />

            <Tab
              text={'MM1'}
              onClick={() => setMainTab({ mm1: true })}
              conditon={mainTab.mm1}
            />

            {/* <Tab
              text={'MM1 Scraped'}
              onClick={() => setMainTab({ mm1_sc: true })}
              conditon={mainTab.mm1_sc}
            /> */}

            <Tab
              text={'SP Selection'}
              onClick={() => setMainTab({ mlps: true })}
              conditon={mainTab.mlps}
            />
          </div>
        ) : (
          <>
            <div className="flex border-[#F5F5F5]  px-8 text-sm  ">
              <Tab
                text={'NM5'}
                onClick={() => setMainTab({ nm5: true })}
                conditon={mainTab.nm5}
              />
              <Tab
                text={'SP Selection'}
                onClick={() => setMainTab({ mlps: true })}
                conditon={mainTab.mlps}
              />
            </div>
          </>
        )}
      </div>
      <TrackerPart
        is_scraped_matches={mainTab.mm1_sc}
        isInvestor={isInvestor}
        type={type}
      />
    </>
  );
};

export default Tracker;
