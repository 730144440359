import React , {useState,useEffect} from 'react'
import RegisterUserModal from './comps/RegisterUserModal';
import { useSelector,useDispatch } from 'react-redux';
import { getRegisteredUserActions } from '../../../redux/actions/RegisteredUserActions'; 
import { RootState } from '../../../types/RootState';
import Pagination from '../pagination/Pagination';
import Loading from '../../../libs/Loading';
import UpdateModal from './comps/UpdateModal';
import {toast} from "react-toastify";
import axios from 'axios';



const RegisteredUsers = () => {
    

    const [showModal,setShowModal] = useState(false);
    const [roleValue,setRoleValue] = useState("all")
    const [updateModal , setUpdateModal] = useState(false);

    const dispatch = useDispatch();
    // continue from here
    const {data,loading,error,query}= useSelector((state:RootState) => state.getRegisteredUser);
    
    const [currentPage, setCurrentPage] = useState(query?.page ?? 1);
    const [limit, setLimit] = useState(query?.limit ?? 50);

    const [updateData,setUpdateData] = useState({
      name:"",mobile:"",countryCode:"",email:"",_id:""
    }) 
    
    
    // const [data, setData] = useState(dummyUserData);
    
    const updateHandler = (data)=>{

      const {name,mobile,countryCode,email,_id} = data;
      // console.log(data);

      setUpdateData((prev)=>{

        return {...prev,name:name,mobile:mobile,countryCode:countryCode,email:email,_id:_id};
      })

      setUpdateModal(true);

    }

    const roleHandler = (event) => {
        const newRoleValue = event.target.value;
        // console.log(newRoleValue)
        setRoleValue(newRoleValue);
    };

    const deleteHandler = async(item)=>{

      
    const toastId = toast.loading("Deleting User");

    const tokenString = localStorage.getItem("token");
    try{
        if(tokenString){
            const tokenObject = JSON.parse(tokenString);
            const token = tokenObject.token;

            const response  = await axios.put(`${process.env.REACT_APP_API_URL_2}/user/suspenduser`,{email:item?.email,_id:item?._id},

                {
                    headers: {
                    Authorization: `Bearer ${token}`
                    }
                }
            )

            if(response?.data?.success){
                toast.dismiss(toastId);
                toast.success("user deleted successfully");
            }
            setUpdateModal(false);
            dispatch(
              getRegisteredUserActions({ subRole:roleValue,currentPage, limit: limit }),
            );
        }
    }catch(error){
        toast.dismiss(toastId);
        toast.error("couldn't delete the user");
        console.log(error,"couldn't delete user details");
        setUpdateModal(false);
    }



    }

    useEffect(() => {
      
      if (
        JSON.stringify(query) !==
        JSON.stringify({  subRole:roleValue , currentPage, limit: limit })
      )
        dispatch(
          getRegisteredUserActions({ subRole:roleValue,currentPage, limit: limit }),
        );

        // console.log(data);
    }, [limit, currentPage , roleValue]);


    return (
    <div className='w-full h-screen   flex flex-row justify-center   relative'>

        


       <div className='w-11/12 mt-10 px-2 py-2  max-h-screen overflow-y-scroll'>
            <div className='flex flex-row  bg-white px-4 py-2 justify-between  rounded-lg'>

                    <div>
                        <select className='px-2 py-3 rounded-lg focus:outline border-2' value = {roleValue} onChange={roleHandler}>
                            <option value="all">All</option>
                            <option value="none">Admin</option>
                            <option value="exists">Client</option>
                            {/* <option value="user">User</option> */}
                        </select>
                    </div>  
                    

                    <button className='bg-blue-500 text-white px-3 py-2 rounded-lg' onClick={()=>{
                        setShowModal(true)
                    }}> Register User </button>

              
            </div>
            {!loading&&<div className="space-y-4 mt-2 bg-white">
                {data?.users?.users!=null ? (
                    data?.users?.users.map((item, index) => (
                    <div key={index} className="bg-white-700 p-4 flex flex-row  justify-between mb-2 rounded-lg shadow-md">
                        <div className=''>
                        <h2 className="text-xl font-bold mb-2">{item?.name}</h2>
                        <p className="text-sm">Role: <span className="font-medium">{item?.subRole==="INVESTOR"?"Client":"Admin"}</span></p>
                        <p className="text-sm">Email: <span className="font-medium">{item?.email}</span></p>
                        <p className="text-sm">Country Code: <span className="font-medium">{item?.countryCode}</span></p>
                        <p className="text-sm">Mobile: <span className="font-medium">{item?.mobile}</span></p>
                        </div>
                        <div className='flex flex-col justify-between'>
                          <button className='px-2 py-2 bg-blue-500 text-white rounded-lg' onClick={()=>{
                            updateHandler(item)
                          }
                        }
                          >Update</button>
                          <button className='px-2 py-2 bg-red-500 text-white rounded-lg' onClick={()=>{
                            deleteHandler(item)
                          }
                        }
                          >Delete</button>
                        </div>
                    </div>
                    ))
                ) : (
                    <p className="text-gray-300">No data available</p>
                )}
            </div>}
            {
              loading&&<Loading/>
            }
        <div>

                  
            </div>
        </div>
        

        {
            showModal&&<RegisterUserModal setShowModal={setShowModal} roleValue={roleValue}/>
        }

        {
          updateModal&&<UpdateModal setUpdateModal={setUpdateModal} setUpdateData={setUpdateData} updateData={updateData} setRoleValue= {setRoleValue} roleValue = {roleValue}/>
        }  

    </div>
    // <div> hello</div>
  )
}

export default RegisteredUsers