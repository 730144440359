export const serialize = function (obj: any) {
  var str: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.map(v => {
        str.push(`${key}=${v}`);
      });
    } else if (typeof value === 'string') {
      if (value.trim() !== '') {
        str.push(`${key}=${value}`);
      }
    } else {
      str.push(`${key}=${value}`);
    }
  });
  return str.join('&');
};
