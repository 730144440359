import classNames from 'classnames';
import React from 'react';

const Dropdown = ({ options, selected, onChnage, disabled = false }) => {
  return (
    <>
      <div
        className={classNames(
          'w-40 px-1 h-10 text-paragraph font-bold text-sm outline-0 border-2 border-borderadmin rounded-lg z-20',
          disabled && 'opacity-40',
        )}
      >
        <select
          disabled={disabled}
          name=""
          className="w-full focus:outline-none h-full rounded-lg"
          id=""
          value={selected}
          onChange={onChnage}
        >
          {options.map(o => {
            return <option value={o.value}>{o.label}</option>;
          })}
        </select>
      </div>
    </>
  );
};

export default Dropdown;
