import React from 'react';
import { Bars, Dna, FallingLines, ThreeDots } from 'react-loader-spinner';

const Loading = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <ThreeDots
        height="80"
        width="70"
        radius="9"
        color="#304FFD92"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loading;
