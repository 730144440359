import React, { useEffect, useState } from 'react';

import logo from 'assets/images/new_logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import {
  Baseball,
  Basketball,
  BellSimple,
  ChartLineUp,
  Circuitry,
  EnvelopeOpen,
  Eye,
  Football,
  Gear,
  GearSix,
  House,
  List,
  Notepad,
  Path,
  PlusSquare,
  User,
  UserCircle,
} from '@phosphor-icons/react';
import { useLocation } from 'react-router-dom';
import AdminPic from 'assets/adminimages/images/Createprompt/Profile.svg';

const navigationItems = [
  {
    label: 'Create',
    link: '',
    image: <PlusSquare size={24} color="#fff" />,
  },
  // {
  //   label: 'View Predictions',
  //   link: '/ad',
  //   image: <Eye size={24} color="#fff" />,
  // },
  {
    label: 'Predictions Stats',

    link: 'stats',
    image: <ChartLineUp size={24} color="#fff" />,
  },
  {
    label: 'Situations ',

    link: 'situations',
    image: <Circuitry size={24} color="#fff" />,
  },
  {
    label: 'Tracker ',

    link: 'tracker',
    image: <Path size={24} color="#fff" />,
  },
  {
    label: 'Users',
    link: 'users',
    image: <Notepad size={24} color="#fff" />,
  },
  {
    label: 'Email',
    link: 'Email',
    image: <EnvelopeOpen size={24} color="#fff" />,
  },
  //   { label: 'NBA', link: '/nba', image: <Basketball size={24} color="#fff" /> },

  //   {
  //     label: 'NHL',

  //     link: '/nhl',
  //     image: <Baseball size={24} color="#fff" />,
  //   },
];
const navigationItemsInvestor = [
  {
    label: 'Tracker ',

    link: 'tracker',
    image: <Path size={24} color="#fff" />,
  },
];

export const TopNavAdmin = () => {
  const [sidebar, setsidebar] = useState(false);
  const location = useLocation();
  const pathSegments = location.pathname.split('/'); // Split the pathname into segments
  const requiredPathSegment = pathSegments[1] ?? '';

  const handlesidebar = () => {
    setsidebar(!sidebar);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 768);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsScreenSmall(window.innerWidth < 768);
    };

    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const loggedInUser = JSON.parse(localStorage.getItem('userInfo')!);

  const userRole = loggedInUser?.role;
  const userSubRole = loggedInUser?.subRole;

  const finalnavOptions =
    userRole === 'ADMIN' && !userSubRole
      ? navigationItems
      : navigationItemsInvestor;

  const [showLogOutOption, setShowLogOutOption] = useState(false);

  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem('userInfo');
    navigate('/login');
  };
  return (
    <>
      <div className="flex bg-bgblack z-[90] sticky top-0 w-full px-8 py-4 justify-between sticky">
        {isScreenSmall && (
          <div className="text-white cursor-pointer" onClick={toggleSidebar}>
            <List className="h-6 w-6" onClick={toggleSidebar} />
          </div>
        )}
        {!isScreenSmall && (
          <>
            <div className="flex gap-x-20">
              <div className="Logo cursor-pointer w-52 flex items-center">
                <img src={logo} />
              </div>
              <ul className="flex text-white font-bold  gap-x-5 pt-1">
                {finalnavOptions.map(item => {
                  return (
                    <>
                      <Link to={item.link} key={item.label}>
                        <li
                          className={`flex items-center justify-center gap-x-2 cursor-pointer mt-[-1px] px-7 py-[10px] rounded-lg ${
                            requiredPathSegment === item.link
                              ? ' text-white bg-bgblue'
                              : ''
                          }`}
                        >
                          <div>
                            {' '}
                            {React.cloneElement(item.image, {
                              color:
                                requiredPathSegment === item.link
                                  ? '#fff'
                                  : '#fff',
                            })}
                          </div>
                          <div>
                            <p className="text-sm">{item.label}</p>
                          </div>
                        </li>
                      </Link>
                    </>
                  );
                })}
              </ul>
            </div>
            <div className="font-bold items-center pt-1">
              <ul className="flex items-center justify-center gap-x-7">
                <li
                  onClick={() => setShowLogOutOption(!showLogOutOption)}
                  className="cursor-pointer relative"
                >
                  <p className="text-bgfadewhite text-sm">
                    <GearSix size={24} color="#fff" opacity={0.9} />
                  </p>
                  {showLogOutOption && (
                    <div
                      onClick={logoutHandler}
                      className="absolute top-[120%] w-24 -left-3 bg-white sahdow rounded-md"
                    >
                      <p className="px-4 py-2 ">Log Out</p>
                    </div>
                  )}
                </li>
                <li>
                  <p className="border-[1px] border-bgfadewhite border-r-2 h-7 opacity-[0.9]"></p>
                </li>
                <li className="cursor-pointer" onClick={handlesidebar}>
                  <p className="text-bgfadewhite text-sm">
                    <BellSimple size={24} color="#fff" opacity={0.9} />
                  </p>
                </li>
                <li>
                  <p className="border-[1px] border-bgfadewhite border-r-2 h-7 opacity-[0.9]"></p>
                </li>
                <li>
                  <div className="flex gap-2 items-center">
                    <UserCircle color="#fff" size={32} />
                    <p className="text-white text-md font-medium">
                      {loggedInUser?.name}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>

      {isScreenSmall && (
        <div
          className={`absolute top-0 left-0 w-64 p-4 h-screen z-[99] bg-white shadow-md transition-transform duration-300 ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <div className=" cursor-pointer" onClick={toggleSidebar}>
            <List className="h-6 w-6" />
          </div>
          <div className="pt-4">
            {navigationItems.map(item => {
              return (
                <>
                  <Link
                    onClick={() => setIsSidebarOpen(false)}
                    to={item.link}
                    key={item.label}
                  >
                    <li
                      className={`flex items-center justify-start gap-x-2 cursor-pointer mt-[-1px] px-4 py-[10px] rounded-lg ${
                        requiredPathSegment === item.link
                          ? ' text-white bg-bgblue'
                          : ''
                      }`}
                    >
                      <div>
                        {' '}
                        {React.cloneElement(item.image, {
                          color:
                            requiredPathSegment === item.link
                              ? '#ffffff'
                              : '#000000',
                        })}
                      </div>
                      <div>
                        <p className="text-sm">{item.label}</p>
                      </div>
                    </li>
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
