import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { accessToken } from '../libs/accessToken';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  _retry?: boolean;
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config): any | Promise<AxiosRequestConfig> => {
    // const user: any = localStorage.getItem('userInfo');
    //

    // if (user && config && config.headers) {
    //   config.headers['Authorization'] = 'Bearer ' + user?.data?.token?.token;
    // }
    return { ...config, ...accessToken() };
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      const originalRequest: AxiosRequestConfigExtended =
        error.config as AxiosRequestConfigExtended;
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        // remove the token from local storage and redirect the user to login page
        // localStorage.removeItem('userInfo');
        // const navigate = getNavigator();
        // if (navigate) {
        //   navigate('/login');
        // }
        window.location.replace('/login');
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
