import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance';
import { Navigate } from 'react-router-dom';
import { serialize } from '../../libs/serialise';
// This fucntion will be called in the loginHndler function.

export const AdminLoginApi = async (data, navigate) => {
  try {
    const payload = {
      userName: data.email,
      password: data.password,
    };
    const response = await axios.post(
      `http://52.60.108.180:5000/auth/login-email`,
      payload,
    );
        
    // Handling token storage

    if (response.data && response.data.payload.token) {
      const userTokenString = JSON.stringify(response.data.payload.token);
      localStorage.setItem('userInfo', userTokenString);
      return response;
    } else {
      console.error('Login failed: Invalid email or password');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};



export const getUsersListApi = async (data) => {
  

  if(data?.subRole=="all"){
    const uri = serialize({pg:data?.currentPage, lm:data.limit });
    return  await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_2}/user/users?${uri}`
    );
  }else{
    const uri = serialize({ subRole:data?.subRole ,pg:data?.currentPage, lm:data.limit });
    return  await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_2}/user/users?${uri}`
    );
  }


};

export const registerUserApi = async(data)=>{
  
    const token = localStorage.getItem("token");
    return await axiosInstance.post(
      `${process.env.REACT_APP_API_URL_2}/auth/register-email`,
      data,
    );

}