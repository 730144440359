import React, { useEffect,useState } from 'react';
import { X } from '@phosphor-icons/react';
import countryCodes from '../countrycodes.json';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch,useSelector } from 'react-redux';
import { RootState } from '../../../../types/RootState';
import { getRegisteredUserActions } from '../../../../redux/actions/RegisteredUserActions'; 

const UpdateModal = ({ setUpdateModal, updateData, setUpdateData ,setRoleValue , roleValue}) => {

  const dispatch = useDispatch();
  const {data,loading,error,query}= useSelector((state:RootState) => state.getRegisteredUser);
  const [currentPage, setCurrentPage] = useState(query?.page ?? 1);
  const [limit, setLimit] = useState(query?.limit ?? 50);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountryChange = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      countryCode: e.target.value,
    }));
  };

  function isNumeric(str) {
    // The ^ and $ ensure the entire string is checked
    // The [0-9] ensures only digits are allowed
    const regex = /^[0-9]+$/;
    return regex.test(str);
  }

  function isAlphabetic(str) {
    // The ^ and $ ensure the entire string is checked
    // The [A-Za-z] ensures only alphabetic characters are allowed
    // The \s allows for spaces in between the characters
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(str);
  }

  const updateHandler = async() =>{

    if(isNumeric(updateData?.mobile) && isAlphabetic(updateData?.name) ){

      const toastId = toast.loading("Updating Info");
  
      const tokenString = localStorage.getItem("token");
      try{
          if(tokenString){
              const tokenObject = JSON.parse(tokenString);
              const token = tokenObject.token;
  
              const response  = await axios.put(`${process.env.REACT_APP_API_URL_2}/user/updateuser`,updateData,
  
                  {
                      headers: {
                      Authorization: `Bearer ${token}`
                      }
                  }
              )
  
              if(response?.data?.success){
                  toast.dismiss(toastId);
                  toast.success("user updated successfully");
              }
              setUpdateModal(false);
              dispatch(
                getRegisteredUserActions({ subRole:roleValue,currentPage, limit: limit }),
              );
          }
      }catch(error){
          toast.dismiss(toastId);
          toast.error("couldn't update the user");
          console.log(error,"couldn't update user details");
          setUpdateModal(false);
      }
    }else{
      toast.error("Mobile and Name with special characters is not valid")
    }


  }

  return (
    <div className="flex  absolute w-full items-start justify-center min-h-screen bg-gray-800">
            
      <div className="bg-gray-700 p-8 rounded-lg shadow-lg w-full mt-8 max-w-md">
        <div>
          <h1 className='text-center text-xl text-white font-bold'>Update User</h1>
        </div>
        <div className='flex w-full justify-end'>
          <button onClick={() => {
            setUpdateModal(false);
          }}>
            <X className='text-2xl text-white' />
          </button>
        </div>
        
        <div className='w-full h-full'>
          <form className='w-full max-w-lg mx-auto'>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                pattern="^[A-Za-z]+(\s[A-Za-z]+)*$"
                value={updateData.name}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="mobile" className="block text-sm font-medium text-gray-300">Mobile:</label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                pattern='^[0-9]{1,15}$'
                value={updateData.mobile}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="countryCode" className="block text-sm font-medium text-gray-300">Country Code:</label>
              <select
                id="countryCode"
                name="countryCode"
                value={updateData.countryCode}
                onChange={handleCountryChange}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-600 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.dial_code} selected={country.name === 'United States'}>
                    {country.name} ({country.dial_code})
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={updateData.email}
                onChange={handleInputChange}
                required
                disabled
                className="mt-1 block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm bg-gray-800 text-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="w-full bg-blue-500 text-gray-200 py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                type="button"
                onClick={updateHandler}
              >
                Update
              </button>
            </div>
            <button
              type="button"
              className="w-full bg-red-500 text-gray-200 py-2 px-4 rounded-md shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
