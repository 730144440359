import React, { useEffect, useState } from 'react';
import Tab from '../Tracker/comps/Tab';
import CreatePrediction from '.';
import { getTobePredictedMatches } from 'api/Admin/Predictions';
import { IPrediction } from './Interface';
import CreatePredictionV2 from './index_v2';

const Predict = () => {
  const [mainTab, setMainTab] = useState<{
    nba?: boolean;
    mlb?: boolean;
    nfl?: boolean;
  }>({
    nba: false,
    mlb: false,
    nfl: true,
  });
  const type = mainTab.nba
    ? 'BASKETBALL'
    : mainTab.mlb
    ? 'BASEBALL'
    : mainTab.nfl
    ? 'FOOTBALL'
    : '';

  const [tobematches, setMatches] = useState<IPrediction[]>();
  const [loading, setLoading] = useState(false);
  const getPred = async () => {
    try {
      setLoading(true);
      const res = await getTobePredictedMatches({
        sport: mainTab.nba
          ? 'BASKETBALL'
          : mainTab.mlb
          ? 'BASEBALL'
          : mainTab.nfl
          ? 'FOOTBALL'
          : '',
      });
      setMatches(res.data.payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPred();
  }, [mainTab]);

  return (
    <>
      <div className="pt-10 px-8">
        <div className="flex border-[#F5F5F5]  text-sm  ">
          <Tab
            text={'MLB'}
            onClick={() => setMainTab({ mlb: true })}
            conditon={mainTab.mlb}
          />
          <Tab
            text={'NBA'}
            onClick={() => setMainTab({ nba: true })}
            conditon={mainTab.nba}
          />
          <Tab
            text={'NFL'}
            onClick={() => setMainTab({ nfl: true })}
            conditon={mainTab.nfl}
          />
        </div>

        {(type === 'BASEBALL' || type === 'BASKETBALL') && (
          <CreatePrediction
            getPred={getPred}
            tobematches={tobematches}
            loading={loading}
            type={type}
          />
        )}
        {type === 'FOOTBALL' && (
          <CreatePredictionV2
            getPred={getPred}
            tobematches={tobematches}
            loading={loading}
            type={type}
          />
        )}
      </div>
    </>
  );
};

export default Predict;
