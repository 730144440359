import React from 'react'

const DataRow = ({
    prediction,
    mlpSelection,
    isMmlpSelection,
    sport = 'BASKETBALL',
  }: {
    prediction: any;
    mlpSelection: any;
    isMmlpSelection: any;
    sport: string;
  }) => {
  return (
    <div className="w-full">
      <div className="w-full text-center">
        <div className="border-b flex justify-between items-center px-10 py-2">
          <span className="text-sm md:text-base">m/s:</span>
          <span className="text-sm md:text-base">
            {isMmlpSelection ? mlpSelection : 'N/A'}
          </span>
        </div>

        <div className="flex flex-row md:flex-row justify-between px-10 py-2 items-center md:gap-2">
          <span className="text-sm md:text-base">NFM1:</span>
          <span className="text-sm md:text-base">
            {prediction ?? 'N/A'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default DataRow
