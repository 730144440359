import { Helmet } from 'react-helmet-async';

import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import Dashboard from '../../admincomponents/Dashboard';
import { TopNavAdmin } from '../../admincomponents/common/TopNav';
import PredictionStats from '../../admincomponents/Stats';
import Situations from '../../admincomponents/Situations';
import IndividualMatch from '../../admincomponents/Situations/IndividualMatch';
import CreatePrediction from '../../admincomponents/Predict';
import Tracker from '../../admincomponents/Tracker/Tracker';
import Predict from '../../admincomponents/Predict/Predict';
import RegisteredUsers from '../../admincomponents/Users';
import Email from '../../admincomponents/Email';

export function AdminPage() {
  const loggedInUser = JSON.parse(localStorage.getItem('userInfo')!);

  const userRole = loggedInUser?.role;
  const userSubRole = loggedInUser?.subRole;

  const isInvestor = userRole === 'ADMIN' && userSubRole === 'INVESTOR';

  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedInUser) {
      navigate('/login');
    }
  }, [loggedInUser]);
  return (
    <>
      <Helmet>
        <title>Admin Page</title>
        <meta
          name="description"
          content="A React Boilerplate application homepage"
        />
      </Helmet>
      <div className="">
        <TopNavAdmin />
        <div className="bg-bggreylight  flex flex-col min-h-[calc(100vh-80px)] overflow-y-scroll">
          <Routes>
            {/* <Route path="/" element={<Dashboard />} /> */}
            <Route
              path="/"
              element={!isInvestor ? <Predict /> : <Navigate to={'/tracker'} />}
            />
            <Route
              path="/create-parlay"
              element={!isInvestor ? <></> : <Navigate to={'/tracker'} />}
            />
            <Route
              path="stats"
              element={
                !isInvestor ? <PredictionStats /> : <Navigate to={'/tracker'} />
              }
            />
            <Route path="tracker" element={<Tracker />} />
            <Route
              path="situations"
              element={
                !isInvestor ? <Situations /> : <Navigate to={'/tracker'} />
              }
            />
            <Route
              path="situations/:macth_id"
              element={
                !isInvestor ? <IndividualMatch /> : <Navigate to={'/tracker'} />
              }
            />

            <Route

              path='users'
              element = {
                <RegisteredUsers/> 
              }
            />

            <Route

            path='email'
            element = {
              <Email/> 
            }
            />    

          </Routes>
        </div>
      </div>
    </>
  );
}
