import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';
import { Navigate } from 'react-router-dom';
// This fucntion will be called in the loginHndler function.

export const AdminLoginApi = async (data, navigate) => {
  try {
    const payload = {
      email: data.email,
      password: data.password,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_2}/auth/login-email`,
      payload,
    );

    // Handling token storage

    if (response.data) {
      const userTokenString = JSON.stringify(response.data.payload.token);

      window.localStorage.setItem(
        'userInfo',
        JSON.stringify(response.data.payload.user),
      );
      return response;
    } else {
      console.error('Login failed: Invalid email or password');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

export const sendLoginCode = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_2}/auth/generate-code`,
    data,
  );
};

export const validateLoginCode = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_2}/auth/validate-code`,
    data,
  );
};
