import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { getUsersListApi,registerUserApi } from '../../api/Admin/Auth';
import { toast } from 'react-toastify';
import { GET_REGISTERED_USER_LIST_REQUEST,GET_REGISTERED_USER_LIST_SUCCESS,
  GET_REGISTERED_USER_LIST_FAILURE,REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE } from "../constants/RegisterUsersConstants"

export const getRegisteredUserActions =
  ({subRole,currentPage,limit}) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({
        type: GET_REGISTERED_USER_LIST_REQUEST,
      });

      const { data } = await getUsersListApi({subRole,currentPage,limit});

      dispatch({
        type: GET_REGISTERED_USER_LIST_SUCCESS,
        payload: data.payload,
        query : {page:currentPage,limit,subRole}
      });
      
    } catch (error: any) {
      dispatch({
        type: GET_REGISTERED_USER_LIST_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const registerUserActions =
  (userData) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({
        type: REGISTER_USER_REQUEST,
      });

      const { data } = await registerUserApi(userData);

      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: data.payload,
      });
      
    } catch (error: any) {
      
      toast.error("Cannot Add User")
      dispatch({
        type: REGISTER_USER_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };




