import React from 'react';
// import avatar from 'assets/images/auth/Group 161167.svg';
// import recreatelogo from 'assets/images/auth/Group 20.svg';
import Subtract from 'assets/images/new_logo.svg';
import SimpleSlider from './Slider';

const SBSLeft = () => {
  return (
    <>
      <div className="w-[35%] hidden bg-bgleftImg bg-cover lg:flex flex-col  items-center justify-center relative">
        <div className="flex flex-col gap-y-1 absolute top-20 cursor-pointer">
          <div className="flex gap-x-1">
            <div>
              <img src={Subtract} className="w-full" />
            </div>
          </div>
        </div>
        <div className="w-full absolute bottom-16">
          {/*<SimpleSlider />*/}
          <div className="text-2xl">
            <p className="text-white text-center  font-black">
              Traditional Statistics <br /> & Advanced Metrics{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SBSLeft;
