import React, { useEffect, useState } from 'react';
import Dropdown from './comps/Dropdown';
import { getPredictedMatches } from '../../../api/Admin/Predictions';
import { formatDateTime } from '../Situations';
import { findBetCat, formattedOdds } from '../../../utils/formatOdds';
import { formatCurrency } from '../../../utils/formatCurrency';
import classNames from 'classnames';
import Pagination from '../pagination/Pagination';
import Loading from '../../../libs/Loading';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { Info } from '@phosphor-icons/react';
import { IPrediction } from '../Predict/Interface';

const TrackerPart = ({ type, isInvestor, is_scraped_matches }) => {
  const betTypesOptions = [
    { value: 'low_return', label: 'LR' },
    { value: 'medium_return', label: 'MR' },
    { value: 'high_return', label: 'HR' },
  ];

  const oddsTypesOptions = [
    { value: 'with_odds', label: 'With Odds' },
    { value: 'without_odds', label: 'Without Odds' },
    // { value: 'mlp_selection', label: 'MLP Selection' },
  ];

  const betCategoriesOptions = [
    { value: 'spread', label: 'Spread' },
    { value: 'moneyline', label: 'MoneyLine' },
  ];

  const leaugeOptions = [
    { value: '', label: 'All' },
    { value: 'BASEBALL', label: 'MLB' },
    { value: 'BASKETBALL', label: 'NBA' },
  ];
  const [betType, setBetType] = useState<any>(
    isInvestor ? null : [betTypesOptions[2]],
  );
  const [preOddsType, setPreOddsType] = useState(
    type === 'nm1' ? oddsTypesOptions[0].value : type,
  );
  const [oddsType, setOddsType] = useState(
    type === 'nm1' ? oddsTypesOptions[0].value : type,
  );
  const [betCategory, setBetCategory] = useState(betCategoriesOptions[1].value);
  const [selectedLeauge, setSelectedLeauge] = useState(leaugeOptions[0].value);
  const [oddsGreaterThan, setOddsGreaterThan] = useState<number>(0);

  useEffect(() => {
    setPreOddsType(type === 'nm1' ? oddsTypesOptions[0].value : type);
    setOddsType(type === 'nm1' ? oddsTypesOptions[0].value : type);
  }, [type]);

  const [predictedMatches, setMatches] = useState<{
    predictions: IPrediction[];
    stats: any;
    totalProfits: any;
    pagination: any;
  } | null>();
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState('2024-01-10');
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split('T')[0],
  );
  const [teamName, setTeamName] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [unitPriceBeforeSave, setUnitPriceBeforeSave] = useState<any>(1000);
  const [unitPrice, setUnitPrice] = useState<any>(1000);

  const getPred = async () => {
    try {
      setLoading(true);

      setMatches(null);

      const res = await getPredictedMatches({
        startDate,
        endDate,
        betType,
        teamName,
        unitPrice: Number(unitPrice),
        page,
        limit,
        with_or_without_odds: preOddsType,
        odds_greater_than: oddsGreaterThan,
        sport: selectedLeauge,
        is_scraped_matches: is_scraped_matches,
      });
      setOddsType(preOddsType);
      setMatches(res.data.payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPred();

    setMatches(null);
  }, [limit, page, preOddsType]);

  const appyFilter = () => {
    getPred();
  };

  const clearFilters = () => {
    setStartDate('2024-01-10');
    setTeamName('');
    setEndDate(new Date().toISOString().split('T')[0]);
    setBetType(null);
    setOddsType(oddsTypesOptions[1].value);
    setPreOddsType(oddsTypesOptions[1].value);
    setBetCategory(betCategoriesOptions[1].value);
    getPred();
  };

  return (
    <>
      <div className="w-full h-full mb-10">
        <div className="filter px-8 flex  w-full md:gap-x-8">
          <div className="flex flex-col w-full  w-full rounded-b-lg px-8 pb-9">
            <div className="md:flex sticky top-0 justify-between bg-white pb-7 rounded-b-md">
              <div>
                <div className="md:flex mt-7 px-7 gap-7 items-center">
                  <div className="flex gap-x-2">
                    <div className="w-36  cursor-pointer">
                      <p className="text-sm font-semibold ">From</p>
                      <input
                        className="w-[100%] h-10 text-sm font-bold text-bgparagraph px-2 rounded-lg outline-0 border-2 border-borderadmin cursor-pointer py-2"
                        type="date"
                        id="from" // Add an ID to identify this input
                        value={startDate}
                        onChange={e => {
                          setStartDate(e.target.value);
                          setPage(1);
                        }}
                      />
                    </div>
                    <div className="w-36  cursor-pointer">
                      <p className="text-sm font-semibold ">To</p>
                      <input
                        className="w-[100%] h-10 text-sm font-bold text-bgparagraph px-2 rounded-lg outline-0 border-2 border-borderadmin cursor-pointer py-2"
                        type="date"
                        id="from" // Add an ID to identify this input
                        value={endDate}
                        onChange={e => {
                          setEndDate(e.target.value);
                          setPage(1);
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <p className="text-sm font-semibold ">Bet Type</p>
                    {/* <Dropdown
                      disabled={betCategory === 'spread'}
                      selected={betType}
                      onChnage={e => {
                        setBetType(e.target.value);
                        setPage(1);
                      }}
                      options={betTypesOptions}
                    /> */}

                    <Select
                      isMulti
                      name="colors"
                      options={betTypesOptions}
                      value={betType}
                      onChange={e => setBetType(e)}
                      className="basic-multi-select w-64 text-sm"
                      classNamePrefix="select"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>

                  <div className="w-80">
                    <p className="text-sm font-semibold ">Search Team Name</p>
                    <input
                      value={teamName}
                      onChange={e => {
                        setTeamName(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Boston Celtics"
                      className="w-60 focus:outline-none h-full rounded-lg px-1 h-10 text-paragraph text-sm outline-0 border-2 border-borderadmin rounded-lg z-20"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="md:flex mt-7 px-7 gap-7 items-center">
                  {type === 'nm1' && (
                    <div>
                      <p className="text-sm font-semibold ">W/o or Wo/o</p>
                      <Dropdown
                        selected={preOddsType}
                        onChnage={e => {
                          setPreOddsType(e.target.value);
                          setPage(1);
                        }}
                        options={oddsTypesOptions}
                      />
                    </div>
                  )}
                  {!isInvestor && (
                    <div>
                      <p className="text-sm font-semibold ">Bet Category</p>
                      <Dropdown
                        selected={betCategory}
                        onChnage={e => {
                          setBetCategory(e.target.value);
                          setPage(1);
                          if (e.target.value === 'spread') {
                            setBetType('');
                          }
                        }}
                        options={betCategoriesOptions}
                      />
                    </div>
                  )}

                  {oddsType === 'mlp_selection' && (
                    <div>
                      <p className="text-sm font-semibold ">Leauge</p>
                      <Dropdown
                        selected={selectedLeauge}
                        onChnage={e => {
                          setSelectedLeauge(e.target.value);
                          setPage(1);
                          if (e.target.value === 'spread') {
                            setBetType('');
                          }
                        }}
                        options={leaugeOptions}
                      />
                    </div>
                  )}

                  <div>
                    <p className="text-sm font-semibold ">Odds Greater Than</p>
                    <input
                      value={oddsGreaterThan}
                      onChange={e => setOddsGreaterThan(Number(e.target.value))}
                      className="w-full focus:outline-none h-full rounded-lg px-1 h-10 text-paragraph text-sm outline-0 border-2 border-borderadmin rounded-lg z-20"
                      type="number"
                      name=""
                      id=""
                    />
                  </div>

                  <div className="flex gap-4 mt-4 items-center">
                    <button
                      onClick={clearFilters}
                      className="px-6 py-2 border rounded-md"
                    >
                      Reset
                    </button>
                    <button
                      onClick={appyFilter}
                      className="px-6 py-2 border bg-blue-500 text-white rounded-md"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="grid md:grid-cols-2  mt-7 px-7 gap-2 ">
                  <div className="grid grid-cols-3 text-center border bg-gray-100 ">
                    <div className="p-3 border-r grid grid-rows-2 gap-2">
                      <p className="font-bold">Predicted</p>
                      <p>{predictedMatches?.stats[betCategory]?.predicted}</p>
                    </div>
                    <div className="p-3 border-r grid grid-rows-2 gap-2">
                      <p className="font-bold">Won</p>
                      <p>{predictedMatches?.stats[betCategory].correct}</p>
                    </div>
                    <div className="p-3  grid grid-rows-2 gap-2">
                      <p className="font-bold">Win %</p>
                      {predictedMatches?.stats && (
                        <p>
                          {(
                            (predictedMatches?.stats[betCategory].correct /
                              predictedMatches?.stats[betCategory].predicted) *
                            100
                          ).toFixed(2)}
                          %
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 text-center border bg-gray-100 ">
                    <div className="p-3 border-r grid grid-rows-2 ">
                      <p className="text-sm font-semibold ">Unit Price ($)</p>
                      <div className="flex gap-2">
                        <input
                          value={unitPriceBeforeSave}
                          onChange={e => setUnitPriceBeforeSave(e.target.value)}
                          className="w-full focus:outline-none h-full rounded-lg px-1 h-10 text-paragraph text-sm outline-0 border-2 border-borderadmin rounded-lg z-20"
                          type="number"
                          name=""
                          id=""
                        />
                        <button
                          onClick={() => setUnitPrice(unitPriceBeforeSave)}
                          className="px-3 border bg-blue-300 rounded-md text-sm"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="p-3  grid grid-rows-2 gap-2">
                      <p className="font-bold">Profit</p>
                      {predictedMatches && (
                        <p
                          className={classNames(
                            'font-bold',
                            predictedMatches?.totalProfits[betCategory] > 0
                              ? ' text-green-500'
                              : 'text-rose-500',
                          )}
                        >
                          {formatCurrency(
                            predictedMatches?.totalProfits[betCategory],
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 bg-white h-full overflow-y-auto px-7 py-3 rounded-md">
              {
                <>
                  <div className="flex justify-end">
                    {predictedMatches && (
                      <Pagination
                        onLimitChnage={e => setLimit(e.target.value)}
                        setPage={setPage}
                        currentPage={Number(page)}
                        totalCount={
                          predictedMatches?.pagination?.totalDocuments
                        }
                        limit={limit}
                      />
                    )}
                  </div>
                  {loading ? (
                    <Loading />
                  ) : (
                    <table className="border-collapse flex-1 border border-bordercheckbox mt-4 w-full">
                      <thead>
                        <tr className="text-bgparagraph opacity-[0.5] font-bold">
                          <th className="border border-bordergrey p-2 bg-bggreyfade  py-[10px]">
                            <div className="flex justify-start px-4">Date</div>
                          </th>
                          {oddsType === 'mlp_selection' && (
                            <th className="border border-bordergrey p-2 bg-bggreyfade  py-[10px]">
                              <div className="flex justify-start px-4">
                                League
                              </div>
                            </th>
                          )}
                          <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                            Match
                          </th>
                          <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                            Predicted Winner
                          </th>
                          <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                            PSD
                          </th>
                          <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                            Actual Winner
                          </th>
                          {(type === 'nm2' ||
                            type === 'mlp_selection' ||
                            type === 'nm2V') && (
                            <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                              Val/Rev. Value
                            </th>
                          )}
                          <th className="border border-bordergrey bg-bggreyfade  py-[10px]">
                            ASD
                          </th>
                          <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                            Is Won
                          </th>
                          <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                            Odds
                          </th>
                          <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                            Bet Type
                          </th>
                          <th className="border border-bordergrey  bg-bggreyfade  py-[10px]">
                            Profit
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {predictedMatches?.predictions?.map(m => {
                          const woPrediction = m?.with_odds?.prediction;
                          const wooPrediction = m?.without_odds?.prediction;
                          const mlpSelectionPrediction =
                            m?.mlp_selection?.prediction;
                          const nm2Prediction = m?.nm2?.prediction;
                          const nm3Prediction = m?.nm3?.prediction;
                          const nm4Prediction = m?.nm4?.prediction;
                          const nm5Prediction = m?.nm5?.prediction;
                          const nmplPrediction = m?.nmpl?.prediction;
                          const nm2VPrediction = m?.nm2V?.prediction;
                          const mm1Prediction = m?.mlb?.mm1?.prediction;

                          const fp =
                            oddsType === 'with_odds'
                              ? woPrediction
                              : oddsType === 'without_odds'
                              ? wooPrediction
                              : oddsType === 'mlp_selection'
                              ? mlpSelectionPrediction
                              : oddsType === 'nm2'
                              ? nm2Prediction
                              : oddsType === 'nm3'
                              ? nm3Prediction
                              : oddsType === 'nm4'
                              ? nm4Prediction
                              : oddsType === 'nm5'
                              ? nm5Prediction
                              : oddsType === 'nmpl'
                              ? nmplPrediction
                              : oddsType === 'nm2V'
                              ? nm2VPrediction
                              : mm1Prediction;

                          let pred_object: any = m;
                          const type_ = oddsType.split('.');

                          type_.forEach(key => {
                            if (pred_object[key]) {
                              pred_object = pred_object[key];
                            }
                          });

                          const leauge =
                            m.sport === 'BASKETBALL'
                              ? 'NBA'
                              : m.sport === 'BASEBALL'
                              ? 'MLB'
                              : '';

                          const VOR: any = m?.nm2?.value_or_rev_value?.value
                            ?.isValue
                            ? m?.nm2?.value_or_rev_value?.value
                            : m?.nm2?.value_or_rev_value?.rev_value?.isValue
                            ? m?.nm2?.value_or_rev_value?.rev_value
                            : null;
                          return (
                            <tr className="">
                              <td className="border border-bordergrey pl-4 text-start   items-center text-base text-black py-[10px]">
                                <p>{formatDateTime(m.date)}</p>
                              </td>
                              {oddsType === 'mlp_selection' && (
                                <td className="border border-bordergrey px-4 text-start   items-center text-base text-black py-[10px]">
                                  <div className="flex items-center gap-2">
                                    <img
                                      className="w-8"
                                      src={
                                        leauge === 'MLB'
                                          ? 'https://mlp-images.s3.ca-central-1.amazonaws.com/mlb_logo.png'
                                          : 'https://mlp-images.s3.ca-central-1.amazonaws.com/nba_logo.png'
                                      }
                                      alt="MLB"
                                    />
                                    <p>{leauge}</p>
                                  </div>
                                </td>
                              )}
                              <td className="border border-bordergrey px-4 text-start   items-center text-base text-black py-[10px]">
                                <div className="flex items-center justify-between">
                                  <div className="grid gap-1">
                                    <div className="flex font-medium items-center gap-2">
                                      <img
                                        className="w-8"
                                        src={m.match_id?.hometeam?.image}
                                        alt=""
                                      />
                                      <p>
                                        {m.match_id?.hometeam?.abbreviation}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <img
                                      className="w-5 "
                                      src="https://staging.majorleaguepicks.ai/static/media/Group%20392.0c182092185a424278e84d5d9fad4464.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="grid gap-1">
                                    <div className="flex font-medium items-center gap-2">
                                      <img
                                        className="w-8"
                                        src={m.match_id?.awayteam?.image}
                                        alt=""
                                      />
                                      <p>
                                        {m.match_id?.awayteam?.abbreviation}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {pred_object?.prediction?.moneyline?.team &&
                              pred_object?.prediction?.moneyline?.team ? (
                                <>
                                  <td className="border border-bordergrey px-4 text-start  items-center text-base text-black py-[10px]">
                                    <div className="flex items-center justify-center gap-2">
                                      <img
                                        className="w-8  object-cover"
                                        src={fp[betCategory].team?.image}
                                        alt={fp[betCategory].team?.abbreviation}
                                      />
                                      {fp && (
                                        <>
                                          <span>
                                            {fp[betCategory].team?.abbreviation}{' '}
                                            ({fp?.moneyline['odds']})
                                          </span>
                                          {oddsType !== 'mlp_selection' && (
                                            <span>
                                              {/* {fp[betCategory].team?.abbreviation}{' '} */}
                                              (
                                              {(
                                                fp?.moneyline['win_porba'] * 100
                                              ).toFixed(2)}
                                              %)
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {(m?.mlp_selection?.comment ||
                                        m?.mlp_selection?.bet_proof) &&
                                        oddsType === 'mlp_selection' && (
                                          <div>
                                            <a
                                              data-tooltip-id={`my-tooltip-${m._id}`}
                                            >
                                              <Info className="cursor-pointer" />
                                            </a>
                                            <Tooltip id={`my-tooltip-${m._id}`}>
                                              <div className="max-w-[400px]">
                                                <p>
                                                  {m?.mlp_selection?.comment}
                                                </p>
                                                {m?.mlp_selection
                                                  ?.bet_proof && (
                                                  <img
                                                    src={
                                                      m?.mlp_selection
                                                        ?.bet_proof
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            </Tooltip>
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  <td className="border border-bordergrey px-4 text-start  items-center text-base text-black py-[10px]">
                                    <p className="text-center">
                                      <p>
                                        {fp?.score_diff
                                          ? Math.abs(fp?.score_diff)
                                          : 'NA'}
                                      </p>
                                    </p>
                                  </td>
                                  <td className="border border-bordergrey px-4 text-start  items-center text-base text-black py-[10px]">
                                    <div className="flex text-center w-full justify-center items-center gap-2">
                                      <img
                                        className="w-8"
                                        src={m?.result?.winner?.image}
                                        alt=""
                                      />
                                      <p>{m?.result?.winner?.abbreviation}</p>
                                    </div>
                                  </td>
                                  {(type === 'nm2' ||
                                    type === 'nm2V' ||
                                    type === 'mlp_selection') && (
                                    <td className="border border-bordergrey px-4 text-start  items-center text-base text-black py-[10px]">
                                      <div className="flex text-center w-full  items-center gap-2">
                                        <div className="flex gap-2 items-center">
                                          <p>
                                            {m?.nm2?.value_or_rev_value?.value
                                              ?.isValue
                                              ? 'Value'
                                              : m?.nm2?.value_or_rev_value
                                                  ?.rev_value?.isValue
                                              ? 'Rev. Value'
                                              : ''}
                                          </p>
                                          {VOR?.grade && (
                                            <div
                                              className={classNames(
                                                'text-xs p-1 px-2 rounded font-bold text-center',
                                                VOR?.grade === 1 &&
                                                  'bg-green-500',
                                                VOR?.grade === 2 &&
                                                  'bg-teal-500',
                                                VOR?.grade === 3 &&
                                                  'bg-purple-500',
                                                VOR?.grade === 4 &&
                                                  'bg-yellow-500',
                                              )}
                                            >
                                              Grade {VOR?.grade}
                                            </div>
                                          )}
                                        </div>
                                        {(m?.nm2?.value_or_rev_value?.value
                                          ?.isValue ||
                                          m?.nm2?.value_or_rev_value?.rev_value
                                            ?.isValue) && (
                                          <div>
                                            <a
                                              data-tooltip-id={`my-tooltip-vrv-${m._id}`}
                                            >
                                              <Info className="cursor-pointer" />
                                            </a>
                                            <Tooltip
                                              id={`my-tooltip-vrv-${m._id}`}
                                            >
                                              <div className="max-w-[400px]">
                                                <div>
                                                  {VOR?.isValue && (
                                                    <div className="border-b py-1 pb-2 flex gap-2 items-center">
                                                      <>
                                                        {' '}
                                                        <img
                                                          className="w-8  object-cover"
                                                          src={VOR.team?.image}
                                                          alt={
                                                            VOR.team
                                                              ?.abbreviation
                                                          }
                                                        />
                                                        <span>
                                                          {
                                                            VOR.team
                                                              ?.abbreviation
                                                          }{' '}
                                                          ({VOR.odds})
                                                        </span>
                                                      </>
                                                    </div>
                                                  )}
                                                  <p className="border-b py-1">
                                                    Value -{' '}
                                                    {VOR?.isValue
                                                      ? 'Yes'
                                                      : 'NO'}
                                                  </p>
                                                  <p className="border-b py-1">
                                                    Value % -{' '}
                                                    {VOR?.isValue
                                                      ? `${VOR?.pct?.toFixed(
                                                          2,
                                                        )}%`
                                                      : 'N/A'}
                                                  </p>

                                                  <div className="border-b flex gap-2 items-center py-1">
                                                    <p>Grade - </p>
                                                    {VOR?.grade && (
                                                      <div
                                                        className={classNames(
                                                          'text-xs p-1 px-2 rounded font-bold text-center',
                                                          VOR?.grade === 1 &&
                                                            'bg-green-500',
                                                          VOR?.grade === 2 &&
                                                            'bg-teal-500',
                                                          VOR?.grade === 3 &&
                                                            'bg-purple-500',
                                                          VOR?.grade === 4 &&
                                                            'bg-yellow-500',
                                                        )}
                                                      >
                                                        Grade {VOR?.grade}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </Tooltip>
                                          </div>
                                        )}
                                        {/* <img
                                        className="w-8"
                                        src={m?.result?.winner?.image}
                                        alt=""
                                      />
                                      <p>{m?.result?.winner?.abbreviation}</p> */}
                                      </div>
                                    </td>
                                  )}
                                  <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">
                                    <p>{m?.result?.score_diff}</p>
                                  </td>
                                  <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">
                                    {betCategory === 'moneyline' &&
                                    pred_object?.cashed_out?.is_cashed_out ? (
                                      <>
                                        <div
                                          className={classNames(
                                            'text-sm p-1 px-2 w-4/5 mx-auto rounded font-bold text-center',
                                            'bg-green-500',
                                          )}
                                        >
                                          Cashed Out
                                        </div>
                                        <p>
                                          {formattedOdds(
                                            pred_object?.cashed_out?.odds,
                                          )}
                                        </p>
                                      </>
                                    ) : (
                                      <p
                                        className={
                                          pred_object[
                                            betCategory === 'spread'
                                              ? 'is_spread_Won'
                                              : 'isWon'
                                          ]
                                            ? ''
                                            : 'text-rose-500'
                                        }
                                      >
                                        {pred_object[
                                          betCategory === 'spread'
                                            ? 'is_spread_Won'
                                            : 'isWon'
                                        ]
                                          ? 'Won'
                                          : 'Lost'}
                                      </p>
                                    )}
                                  </td>
                                  <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">
                                    <p>
                                      {formattedOdds(
                                        pred_object?.prediction[betCategory]
                                          .odds,
                                      )}
                                    </p>
                                  </td>
                                  <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">
                                    <p className="uppercase">
                                      {
                                        findBetCat(
                                          pred_object?.prediction[betCategory]
                                            .odds,
                                        ).split('_')[0][0]
                                      }
                                      {
                                        findBetCat(
                                          pred_object?.prediction[betCategory]
                                            .odds,
                                        ).split('_')[1][0]
                                      }
                                    </p>
                                  </td>
                                  <td className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]">
                                    <p
                                      className={
                                        m.profits[betCategory] > 0
                                          ? 'text-green-600'
                                          : 'text-rose-400'
                                      }
                                    >
                                      {formatCurrency(m.profits[betCategory])}
                                    </p>
                                  </td>
                                </>
                              ) : (
                                <td
                                  className="border border-bordergrey px-4 text-center  items-center text-base text-black py-[10px]"
                                  colSpan={8}
                                >
                                  <div className="">
                                    <p>
                                      Prediction was not made for this match.
                                    </p>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackerPart;
