import axios from 'axios';
import { serialize } from '../../libs/serialise';
import axiosInstance from '../../utils/axiosInstance';

export const getTobePredictedMatches = async ({sport}) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_2}/prediction/get-future-prediction/${sport}`,
  );
};

export const getPredictedMatches = async ({
  startDate,
  endDate,
  betType,
  teamName,
  page = 1,
  limit = 20,
  unitPrice,
  with_or_without_odds,
  odds_greater_than,
  sport,
  is_scraped_matches,
}) => {
  const arr = betType
    ? betType?.map(e => {
        return e.value;
      })
    : [];

  const uri = serialize({
    startDate,
    endDate,
    betType: betType ? arr.join(',') : '',
    search: teamName,
    page,
    limit,
    unitPrice,
    with_or_without_odds,
    odds_greater_than,
    sport,
    is_scraped_matches,
  });

  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_2}/prediction/get-past-prediction?${uri}`,
  );
};

export const updatePredictionMatches = async data => {
  return await axiosInstance.put(
    `${process.env.REACT_APP_API_URL_2}/prediction/update-prediction`,
    data,
  );
};

export const sendMlpSelections = async data => {
  return await axiosInstance.put(
    `${process.env.REACT_APP_API_URL_2}/prediction/add-mlp-selection`,
    data,
  );
};
